import React from 'react';

import BlueResourceBox from '../../Components/UI/BlueResourceBox/BlueResourceBox';
import Buttons from '../../Components/UI/TitleAndContent/Buttons';
import Title from '../../Components/UI/TitleAndContent/Title';
import Content from '../../Components/UI/TitleAndContent/Content';

import classes from './Liftstation.module.css';

const Liftstation = ({
  id,
  name,
  systemName,
  clasName,
  routeName,
  deleteLiftstationHandler,
  updateLiftstationHandler,
}) => {
  return (
    <BlueResourceBox className={clasName}>
      <div className={classes.flexBoxContainer}>
        <div>
          <Title>Name:</Title>
          <Content>{name}</Content>
          <Title>System Name:</Title>
          <Content>{systemName}</Content>
          <Title>Route Name:</Title>
          <Content>{routeName}</Content>
        </div>
        <Buttons
          id={id}
          deleteHandler={deleteLiftstationHandler}
          successHandler={updateLiftstationHandler}
        />
      </div>
    </BlueResourceBox>
  );
};

export default Liftstation;
