import React, { useState } from 'react'

import Modal from '../../Components/UI/Modal/Modal'
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog'

import axios from '../../axios'

const DeleteRouteModal = ({ setShowModal, id, routes, successHandler }) => {
  const route = routes.find(route => route.id === id)
  const [routeName, setRouteName] = useState('')
  const [alert, setAlert] = useState([{
    id: 1,
    type: 'danger',
    message: `Type the name of the route and press delete. Warning: this is permanent!`
  }, {
    id: 2,
    type: 'warning',
    message: 'Route names do not match'
  }])

  const onChangeRouteName = e => {
    e.preventDefault()
    setRouteName(e.target.value)
    if (e.target.value !== route.name) {
      setAlert(alerts => alerts.filter(alert => alert.id !== 2))
      setAlert(alerts => {
        return alerts.concat({
          id: 2,
          type: 'warning',
          message: 'Route names do not match'
        })
      })
    }
    if (e.target.value === route.name) {
      setAlert(alerts => alerts.filter(alert => alert.id !== 2))
      setAlert(alerts => {
        return alerts.concat({
          id: 2,
          type: 'success',
          message: 'Route names match'
        })
      })
    }
  }

  const onSubmitHandler = e => {
    e.preventDefault()
    if (routeName === route.name) {
      axios.delete(`/routes/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          setShowModal(false)
          successHandler(id)
        }
      }).catch(err => {
        if (err.response.status === 409) {
          setAlert(alerts => alerts.filter(alert => alert.id !== 2))
          setAlert(alerts => {
            return alerts.concat({
              id: 2,
              type: 'danger',
              message: err.response.data.message
            })
          })
        }
      })
    }
  }

  const alertsJsx = alert.map(alert => <AlertDialog key={alert.id} type={alert.type}>{alert.message}</AlertDialog>)

  return (
    <Modal
      setShowModal={setShowModal}
      title={'Delete Route'}
    >
      {alertsJsx}
      <form onSubmit={onSubmitHandler}>
        <label htmlFor={'routeName'}>Route Name</label>
        <input
          id={'routeName'}
          className={'input'}
          type={'text'}
          placeholder={route.name}
          value={routeName}
          onChange={onChangeRouteName}
        />
        <div className={'footer-m'}>
          <button className={`btn cancelBtn`} onClick={() => setShowModal(false)}>Cancel</button>
          <button type={'submit'} className={`btn confirmBtn`}>{'Delete'}</button>
        </div>
      </form>
    </Modal>
  )
}

export default DeleteRouteModal