import React, { useState, useRef, useEffect } from 'react'

import { Link } from 'react-router-dom'

import logo from '../../../img/Combined.png'
import logoMobile from '../../../img/logo-mobile.png'
import logoTablet from '../../../img/logo-tablet.png'
import login from '../../../img/login.png'
import loginMobile from '../../../img/login-mobile.png'
import register from '../../../img/register.png'
import registerMobile from '../../../img/register-mobile.png'
import hamburgerIcon from '../../../img/hamburger-menu.png'

import { loggedIn } from '../../../utils'

import classes from './Navbar.module.css'

const Navbar = () => {
  const sideNav = useRef(null)

  const [navbarOpen, setNavbarOpen] = useState(false)

  const openNavbar = () => {
    setNavbarOpen(true)

    const width = window.innerWidth
    const drawer = sideNav.current
    if (width < 1200) {
      drawer.style.width = '250px'
    }
  }

  const closeNavbar = () => {
    setNavbarOpen(false)

    const width = window.innerWidth
    const drawer = sideNav.current
    if (width < 1200) {
      drawer.style.width = '0'
    }
  }

  useEffect(() => {
    const handleClick = e => {
      const drawer = sideNav.current
      if (drawer.style.width === '250px' && e.target.id !== 'hamburger') {
        closeNavbar()
      }
    }

    document.body.addEventListener('click', handleClick)

    return () => {
      document.body.removeEventListener('click', handleClick)
    }

  }, [])

  if (!loggedIn()) {
    return (
      <div className={classes.navWrapper}>
        <nav className={classes.navPub}>
          <ul className={classes.navContainer}>
            <li className={`${classes.navItem} ${classes.navHome}`}>
              <Link to='/'>
                <img className={classes.logo} src={logo} alt='logo' />
              </Link>
            </li>
            <li className={`${classes.navItem} ${classes.navRegister}`}>
              <Link to='/register'>
                <img className={classes.registerMobile} src={registerMobile} alt="register icon" />
                <img className={classes.registerDesktop} src={register} alt="register icon" />Register
              </Link>
            </li>
            <li className={`${classes.navItem} ${classes.navLogin}`}>
              <Link to='/login'>
                <img className={classes.loginMobile} src={loginMobile} alt="login-icon" />
                <img className={classes.loginDesktop} src={login} alt="login icon" />Login
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    )
  }

  return (
    <div className={classes.navWrapperProtected}>
      <nav className={classes.navPub}>
        <div className={classes.flexContainerHamburger}>
          <ul className={classes.ulLogoProtected}>
            <li className={classes.desktopLogoProtected}>
              <Link to='/'>
                <img className={classes.logo} src={logo} alt='logo' />
              </Link>
            </li>
          </ul>
          <ul className={classes.hamburgerMenu}>
            <li className={`${classes.hamburgerMenu}`}>
              <a href='#' className={`${classes.hamburgerIcon} hamburger`} onClick={openNavbar}>
                <img id={'hamburger'} className={'hamburger'} src={hamburgerIcon} alt="hamburger icon" />
              </a>
            </li>
          </ul>
        </div>
        <div id={'side-nav'} className={classes.sideNav} ref={sideNav}>
          <ul className={classes.navContainerProtected}>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/users'>Users</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/systems'>Systems</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/routes'>Routes</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/permits'>Permits</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/wells'>Wells</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/stations'>Lift Stations</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/readings'>Readings</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/reports'>Reports</Link>
            </li>
            <li className={`${classes.protectedNavItem}`}>
              <Link to='/logout'>Logout</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Navbar