import React, { Fragment, useEffect, useState } from 'react'


import { Link } from 'react-router-dom'

import Container from "../../../Components/UI/Container";
import CompleteCenter from "../../../Components/UI/CompleteCenter";
import AlertDialog from "../../../Components/UI/AlertDialog/AlertDialog";
import WhiteBackground from "../../../Components/UI/WhiteBackground/WhiteBackground";
import classes from "../GaMor/GaMor.module.css";
import WaterLossForm from './WaterLossForm'


const WaterLoss = () => {

  const [alerts, setAlerts] = useState([{
    id: 1,
    type: 'primary',
    message: 'To get the total gallons for a system and a given period of time, please select the system and the date range.'
  }])

  const [totalGallons, setTotalGallons] = useState(-1)

  useEffect(() => {
    document.body.className = 'pinkBackground'
  })

  const alertMessages = alerts.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <h1 className={'text-center'}>Water Loss</h1>
          <CompleteCenter>
            <div>
              <Link className={'breadCrumbLink'} to='/reports'>Reports</Link>
              <span className={'breadcrumb-separator'}>/</span>
              <span className={'breadcrumb-separator'}>Georgia Water Loss Reports</span>
            </div>
          </CompleteCenter>
            <div className={'breadcrumbBorderBottom'} />
            <CompleteCenter>
              {alertMessages}
            </CompleteCenter>
            <CompleteCenter>
              <WaterLossForm setAlerts={setAlerts} setTotalGallons={setTotalGallons} />
            </CompleteCenter>
            <CompleteCenter>
              {totalGallons !== -1 && <h2 className={'text-center'}>Total Gallons: {totalGallons}</h2>}
            </CompleteCenter>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
    </Fragment>
  )
}

export default WaterLoss