import React, { Fragment, useEffect } from 'react';

import Container from '../../Components/UI/Container';

import '../Reports/Reports.css';

import classes from '../Reports/Reports.module.css';

import Report from '../Reports/Report';

import georgia from '../../img/georgia.webp';
import ThreeColumnGrid from "../../Components/UI/ThreeColumnGrid/ThreeColumnGrid";
import WellReadings from "./Well/WellReadings";
import WellReadingsBox from "./WellReadingsBox";
import CompleteCenter from "../../Components/UI/CompleteCenter";
import LiftStationReadingsBox from "./LiftStationReadingsBox";

const Readings = () => {
  useEffect(() => {
    document.body.className = 'reportsPage';
  });

  return (
    <Fragment>
      <Container>
        <header className={classes.header}>
          <CompleteCenter>
            <h1 className={'visibleHeader'}>Readings</h1>
          </CompleteCenter>
        </header>
        <ThreeColumnGrid>
          <WellReadingsBox />
          <LiftStationReadingsBox />
        </ThreeColumnGrid>
      </Container>
    </Fragment>
  );
};

export default Readings;
