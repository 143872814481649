import React, { useState } from 'react';

import Modal from '../../../Components/UI/Modal/Modal';
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog';
import GwwPermitForm from './GwwPermitForm';
import Title from '../../../Components/UI/TitleAndContent/Title';
import Content from '../../../Components/UI/TitleAndContent/Content';

const CreateGwwPermitModal = ({ setShowModal, successHandler }) => {
  const [county, setCounty] = useState('Invalid Permit');
  const [alerts, setAlerts] = useState([
    {
      id: 1,
      type: 'primary',
      message:
        'Enter a permit. Once it has been entered the server will validate it and extract information. If it is valid you can save it.',
    },
  ]);

  const alertsJsx = alerts.map((alert) => (
    <AlertDialog key={alert.id} type={alert.type} children={alert.message} />
  ));

  return (
    <Modal
      title={'Create Ground Water Withdrawal Permit'}
      setShowModal={setShowModal}
    >
      {alertsJsx}
      <Title children={'County'} />
      <Content children={county} />
      <GwwPermitForm
        setShowModal={setShowModal}
        setAlerts={setAlerts}
        alerts={alerts}
        successHandler={successHandler}
        setCounty={setCounty}
      />
    </Modal>
  );
};

export default CreateGwwPermitModal;
