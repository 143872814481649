import React, { useState, useEffect, Fragment } from 'react';
import classes from '../../Components/UI/Modal/Modal.module.css';
import axios from '../../axios';

const RouteForm = ({
  setAlert,
  setShowModal,
  successHandler,
  drivingRoute,
}) => {
  const [routeName, setRouteName] = useState('');

  useEffect(() => {
    if (drivingRoute) {
      setRouteName(drivingRoute.name);
    }
  }, []);

  const createRouteHandler = (e) => {
    e.preventDefault();
    if (routeName.length >= 3 && routeName.length <= 21) {
      axios
        .post(
          '/routes',
          {
            name: routeName,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          setShowModal(false);
          successHandler(res.data.route);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => {
              return alerts.concat({
                id: 2,
                type: 'danger',
                message: err.response.data.message,
              });
            });
          }
        });
    }
  };

  const updateRouteHandler = (e) => {
    e.preventDefault();
    if (routeName.length >= 3 && routeName.length <= 21) {
      console.log('fire');
      axios
        .put(
          `/routes/${drivingRoute.id}`,
          {
            name: routeName,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            successHandler(res.data.route);
          }
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => {
              return alerts.concat({
                id: 2,
                type: 'danger',
                message: err.response.data.message,
              });
            });
          }
        });
    }
  };

  const routeInputChangeHandler = (e) => {
    const routeNameInvalid = {
      id: 2,
      type: 'danger',
      message: 'Route name must be between 3 and 21 characters.',
    };

    const routeNameValid = {
      id: 2,
      type: 'success',
      message: 'Route name is valid.',
    };

    setRouteName(e.target.value);
    if (e.target.value.length < 3 || e.target.value.length > 21) {
      setAlert((alerts) => {
        return alerts.filter((alert) => alert.id !== 2);
      });
      setAlert((alerts) => {
        return alerts.concat(routeNameInvalid);
      });
    }
    if (e.target.value.length >= 3 && e.target.value.length <= 21) {
      setAlert((alerts) => {
        return alerts.filter((alert) => alert.id !== 2);
      });
      setAlert((alerts) => alerts.concat(routeNameValid));
    }
  };

  return (
    <Fragment>
      <form>
        <label htmlFor={'routeName'}>Route Name</label>
        <input
          id={'routeName'}
          className={'input'}
          type={'text'}
          placeholder={'Littleneck Road'}
          onChange={routeInputChangeHandler}
          value={routeName}
        />
        <div className={'form-footer'}>
          <button
            className={`btn cancelBtn`}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            onClick={drivingRoute ? updateRouteHandler : createRouteHandler}
            className={`btn confirmBtn`}
          >
            {drivingRoute ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default RouteForm;
