import React from 'react';

import BlueResourceBox from '../../../Components/UI/BlueResourceBox/BlueResourceBox';
import Title from '../../../Components/UI/TitleAndContent/Title';
import Content from '../../../Components/UI/TitleAndContent/Content';
import Buttons from '../../../Components/UI/TitleAndContent/Buttons';

const GaGwwPermit = ({
  id,
  systemName,
  aquiferName,
  permitNumber,
  county,
  deleteHandler,
}) => {
  return (
    <BlueResourceBox>
      <Title>System Name:</Title>
      <Content>{systemName}</Content>
      <Title>Aquifer Name:</Title>
      <Content>{aquiferName}</Content>
      <Title>Permit Number:</Title>
      <Content>{permitNumber}</Content>
      <Title>County:</Title>
      <Content>{county}</Content>
      <Buttons id={id} deleteHandler={deleteHandler} />
    </BlueResourceBox>
  );
};

export default GaGwwPermit;
