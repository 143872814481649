import React from 'react';

import classes from './Modal.module.css';

const Modal = ({
  setShowModal,
  title,
  confirmButtonText,
  children,
  confirmHandler,
  innerRef
}) => {
  return (
    <div className={classes.modalBackground}>
      <div className={classes.modalContainer} ref={innerRef}>
        <div className={classes.titleCloseBtn}>
          <h1 className={classes.title}>{title}</h1>
          <button onClick={() => setShowModal(false)}> X </button>
        </div>
        <div className={classes.body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
