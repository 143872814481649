import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import excel from '../../img/excel.png'

// css classes
import classes from '../Dashboard/dashboard.module.css'

const ChlorineResidualBox = () => {
  return (
    <WhiteBox className={'.min-height-443px'}>
      <img height={98} src={excel} alt={'excel'} />
      <h3>Annual Chlorine Residual Report</h3>
      <p>
        Generate an Annual Chlorine Residual Report for all systems.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/reports/residual'} className={classes.button}>Generate Report</Link>
      </div>
    </WhiteBox>
  )
}

export default ChlorineResidualBox