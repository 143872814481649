import React from 'react'

import classes from './Container.module.css'
import { loggedIn } from '../../utils'

const Container = props => {

  const moduleClasses = loggedIn() ? props.className ?  `${props.className} ${classes.protectedContainer}` : `${classes.protectedContainer}` : props.className ? `${props.className} ${classes.container}` : `${classes.container}`

  return (
      <div className={moduleClasses}>{props.children}</div>
  )
}

export default Container