import axios from 'axios'

import { logUserOut } from './utils'

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL

const instance = axios.create({ baseURL })

instance.interceptors.response.use(
  response => {
    // Do something with the response data
    return response;
  },
  error => {
    // Check if the error has a HTTP status code of 401
    if (error.response && error.response.status === 401) {
      // remove the token from local storage
      logUserOut()
      window.location = '/login'
    }
    // Pass the error along to the code that sent the request
    return Promise.reject(error)
  }
)

// Add a request interceptor
instance.interceptors.request.use(config => {
  // Get the JWT from local storage
  const jwt = localStorage.getItem('token')

  // If there is a JWT, add it to the request headers
  if (jwt) {
    config.headers['Authorization'] = `Bearer ${jwt}`
  }


  return config;
})

export default instance