import React from 'react'

import classes from './Users.module.css'

import phoneIcon from '../../img/phoneIcon.png'

const Phone = ({children}) => {
  return (
    <div className={classes.centerText}><img className={classes.routeIcon} src={phoneIcon} alt={'phone'} />{children}</div>
  )
}

export default Phone