import React from 'react'

import CompleteCenter from '../../Components/UI/CompleteCenter'

import classes from './VerifyEmail.module.css'
import redXCircle from '../../img/red-x-circle.png'
import blueCheckbox from '../../img/blue-checkbox.png'

const WhiteBox = ( { children, success } ) => {
    if (!success) {
      return (
      <CompleteCenter className={classes.inheritMinHeight}>
        <div className={classes.whiteBox}>
          <img src={redXCircle} alt="red x circle" className={'center-image'} />
          <h1 className={'text-center'}>Email Verification Failed!</h1>
          {children}
        </div>
      </CompleteCenter>
      )
    }

    return (
<CompleteCenter className={classes.inheritMinHeight}>
  <div className={classes.whiteBox}>
    <img src={blueCheckbox} alt="blue checkbox" className={'center-image'} />
    <h1 className={'text-center'}>Email Verification Successful!</h1>
    {children}
  </div>
</CompleteCenter>
    )
}

export default WhiteBox