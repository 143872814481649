import React from 'react';
import BlueResourceBox from '../../Components/UI/BlueResourceBox/BlueResourceBox';
import Title from '../../Components/UI/TitleAndContent/Title';
import Content from '../../Components/UI/TitleAndContent/Content';
import Buttons from '../../Components/UI/TitleAndContent/Buttons';

const Well = ({
  id,
  wellName,
  plantNumber,
  sourceNumber,
  routeId,
  routeName,
  systemId,
  systemName,
  gwwPermitId,
  gwwPermitNumber,
  updateWellHandler,
  deleteWellHandler,
}) => {
  return (
    <BlueResourceBox>
      <Title>Well Name:</Title>
      <Content>{wellName}</Content>
      <Title>System Name:</Title>
      <Content>{systemName}</Content>
      <Title>Source Number:</Title>
      <Content>{sourceNumber}</Content>
      <Title>Plant Number:</Title>
      <Content>{plantNumber}</Content>
      <Title>Route Name:</Title>
      <Content>{routeName}</Content>
      <Title>GWW Permit Number:</Title>
      <Content>{gwwPermitNumber === null ? 'N/A' : gwwPermitNumber}</Content>
      <Buttons
        id={id}
        deleteHandler={deleteWellHandler}
        successHandler={updateWellHandler}
      />
    </BlueResourceBox>
  );
};

export default Well;
