import React from 'react';

import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import Dashboard from './Pages/Dashboard/Dashboard';
import Reports from './Pages/Reports/Reports';
import GaMor from './Pages/Reports/GaMor/GaMor';
import Permits from './Pages/Permits/Permits';
import GaSdwPermits from './Pages/Permits/GaSdw/GaSdwPermits';
import RoutesPage from './Pages/Routes/Routes';
import Users from './Pages/Users/Users';
import Systems from './Pages/Systems/Systems';
import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import Logout from './Pages/Logout';
import Wells from './Pages/Wells/Wells';
import GaGwwPermits from './Pages/Permits/GaGww/GaGwwPermits';
import Liftstations from './Pages/Liftstations/Liftstations';
import WaterLoss from './Pages/Reports/WaterLoss/WaterLoss';
import GaGww from './Pages/Reports/GaGww/GaGww';
import LiftStationReport from "./Pages/Reports/LiftStation/LiftStationReport";

import Navbar from './Components/UI/Navbar/Navbar';
import Footer from './Components/UI/Footer';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { loggedIn } from './utils';
import Readings from './Pages/Readings/Readings';
import WellReadings from './Pages/Readings/Well/WellReadings';
import ResidualReport from "./Pages/Reports/Residual/ResidualReport";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route
          path='/'
          element={loggedIn() ? <Navigate to='/dashboard' /> : <Home />}
        />
        <Route path={'logout'} element={<Logout />} />
        <Route
          path='/login'
          element={loggedIn() ? <Navigate to='/dashboard' /> : <Login />}
        />
        <Route
          path='/register'
          element={loggedIn() ? <Navigate to='/dashboard' /> : <Register />}
        />
        <Route
          path='/dashboard'
          element={loggedIn() ? <Dashboard /> : <Navigate to='/login' />}
        />
        <Route
          path='/reports'
          element={loggedIn() ? <Reports /> : <Navigate to='/login' />}
        />
        <Route
          path='/reports/ga/gww'
          element={loggedIn() ? <GaGww /> : <Navigate to='/login' />}
        />
        <Route
          path='/reports/ga/mor'
          element={loggedIn() ? <GaMor /> : <Navigate to='/login' />}
        />
        <Route
          path='/reports/ga/wlr'
          element={loggedIn() ? <WaterLoss /> : <Navigate to='/login' />}
        />
        <Route
          path='/reports/lift-station'
          element={loggedIn() ? <LiftStationReport /> : <Navigate to='/login' />}
        />
        <Route
          path='/reports/residual'
          element={loggedIn() ? <ResidualReport /> : <Navigate to='/login' />}
        />
        <Route
          path='/permits'
          element={loggedIn() ? <Permits /> : <Navigate to='/login' />}
        />
        <Route
          path='/permits/ga/sdw'
          element={loggedIn() ? <GaSdwPermits /> : <Navigate to='/login' />}
        />
        <Route
          path='/permits/ga/gww'
          element={loggedIn() ? <GaGwwPermits /> : <Navigate to='/login' />}
        />
        <Route
          path='/stations'
          element={loggedIn() ? <Liftstations /> : <Navigate to='/login' />}
        />
        <Route
          path='/routes'
          element={loggedIn() ? <RoutesPage /> : <Navigate to='/login' />}
        />
        <Route
          path='/users'
          element={loggedIn() ? <Users /> : <Navigate to='/login' />}
        />
        <Route
          path='/systems'
          element={loggedIn() ? <Systems /> : <Navigate to='/login' />}
        />
        <Route
          path='/wells'
          element={loggedIn() ? <Wells /> : <Navigate to='/login' />}
        />
        <Route
          path='/readings'
          element={loggedIn() ? <Readings /> : <Navigate to='/login' />}
        />
        <Route
          path='/readings/well'
          element={loggedIn() ? <WellReadings /> : <Navigate to='/login' />}
        />
        <Route path='*' element={<NotFound />} />
        <Route path='/verify-email/:hash' element={<VerifyEmail />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
