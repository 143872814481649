import React, { useState } from 'react';
import axios from '../../axios';

import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import LiftstationForm from './LiftstationForm';
const CreateLiftstationModal = ({ setShowModal, successHandler }) => {
  const [liftstationName, setLiftstationName] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('0');
  const [selectedSystem, setSelectedSystem] = useState('0');

  const createLiftstationAlert = {
    id: 1,
    type: 'primary',
    message: 'To create a Liftstation, please enter a name for the well.',
  };

  const liftstationNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'Liftstation name must be between 2 and 23 characters.',
  };

  const liftstationNameValid = {
    id: 2,
    type: 'success',
    message: 'Liftstation name is valid.',
  };

  const [alert, setAlert] = useState([
    createLiftstationAlert,
    liftstationNameInvalid,
  ]);

  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));

  const routeChangeHandler = (e) => {
    setSelectedRoute(e.target.value);
  };

  const systemChangeHandler = (e) => {
    setSelectedSystem(e.target.value);
  };
  const createLiftstationHandler = (e) => {
    e.preventDefault();
    if (liftstationName.length >= 2 || liftstationName.length <= 25) {
      console.log('fired');
      axios
        .post(
          '/lift-stations',
          {
            name: liftstationName,
            routeId: selectedRoute,
            systemId: selectedSystem,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            successHandler(res.data.liftStation);
            setShowModal(false);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          if (err.response.data.message) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => [
              ...alerts,
              { id: 2, type: 'danger', message: err.response.data.message },
            ]);
          }
        });
    }
  };

  const props = {
    setAlert,
    setShowModal,
    createLiftstationHandler,
    liftstationNameValid,
    liftstationNameInvalid,
    liftstationName,
    setLiftstationName,
    selectedRoute,
    routeChangeHandler,
    selectedSystem,
    systemChangeHandler,
  };
  return (
    <Modal title={'Create Liftstation'} setShowModal={setShowModal}>
      {alertsJsx}
      <LiftstationForm {...props} />
    </Modal>
  );
};

export default CreateLiftstationModal;
