import React, {useState, useEffect, Fragment} from 'react'

import axios from "../../../axios";
const moment = require('moment')

import classes from './WaterLossForm.module.css'
import CompleteCenter from "../../../Components/UI/CompleteCenter";

const WaterLossForm = ({ setAlerts, setTotalGallons }) => {
  const [waterSystem, setWaterSystem] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [systemId, setSystemId] = useState('-1')

  useEffect(() => {
    const startDate = moment().subtract('1', 'month').startOf('month').add(5, 'days').format('YYYY-MM-DD')
    setStartDate(startDate)
    setEndDate(moment(startDate).add(1, 'month').format('YYYY-MM-DD'))
  }, [])

  useEffect(() => {
    axios.get(`/systems`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      const systems = res.data.systems
      for (let i = 0; i < systems.length; i++) {
        const system = {
          id: systems[i].systemId,
          name: systems[i].systemName
        }
        setWaterSystem(prevWaterSystemState => [...prevWaterSystemState, system])
      }
    })
  }, [])

  const systemOptions = waterSystem.map(system => <option key={system.id} value={system.id}>{system.name}</option>)

  const startDateHandler = (e) => {
    setStartDate(e.target.value)
  }

  const endDateHandler = (e) => {
    setEndDate(e.target.value)
  }

  const systemIdHandler = (e) => {
    setSystemId(e.target.value)
  }

  const clickHandler = e => {
    e.preventDefault()
    if (systemId === '-1') {
      setAlerts(alerts => [{id: 1, type: 'warning', message: 'Please select a system.'}])
    } else {
      console.log('submit')
      axios.post(`/systems/${systemId}/total-gallons-pumped`, {
        startingDate: startDate,
        endingDate: endDate
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => {
        setTotalGallons(res.data.totalGallonsPumped)
      }).catch(err => {
        console.log(err)
      })
    }

  }

  return (
    <Fragment>
      <div style={{display: "block"}}>

        <label htmlFor="waterSystem">Water System</label>
        <select className={'input2'} name="waterSystem" id="waterSystem" value={systemId} onChange={systemIdHandler}>
          <option value="-1">Select a Water System</option>
          {systemOptions}
        </select>

        <div className={classes.flexBoxContainer}>
          <div className={'wrapper'}>
            <label htmlFor="startDate">Start Date</label>
            <input
              type={'date'}
              name={'startDate'}
              id={'startDate'}
              min={'2022-09-01'}
              value={startDate}
              onChange={startDateHandler}
            />
          </div>
          <div className={'wrapper'}>
            <label htmlFor="endDate">End Date</label>
            <input
              type={'date'}
              name={'endDate'}
              id={'endDate'}
              min={'2022-10-01'}
              value={endDate}
              onChange={endDateHandler}
            />
          </div>
        </div>
        <CompleteCenter>
          <button onClick={clickHandler} className={'btn confirmBtn'}>Calculate Total Gallons Pumped</button>
        </CompleteCenter>




      </div>
    </Fragment>


  )


}

export default WaterLossForm