import React, { Fragment, useEffect, useState } from 'react';

import Container from '../../Components/UI/Container';
import WhiteBackground from '../../Components/UI/WhiteBackground/WhiteBackground';
import FlexboxContainerHeader from '../../Components/UI/Heading/FlexboxContainerHeader';
import HeadingFlexItem from '../../Components/UI/Heading/HeadingFlexItem';
import GreenButton from '../../Components/UI/GreenButton/GreenButton';
import CompleteCenter from '../../Components/UI/CompleteCenter';
import Border from '../../Components/UI/Heading/Border';
import Spinner from '../../Components/UI/Spinner/Spinner';
import FourColumnGrid from '../../Components/UI/FourColumnGrid/FourColumnGrid';
import Route from './Route';

import axios from '../../axios';

import classes from './Routes.module.css';
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid';
import DeleteRouteModal from './DeleteRouteModal';
import GrayText from '../../Components/UI/GrayText';
import CreateRouteModal from './CreateRouteModal';
import UpdateRouteModal from './UpdateRouteModal';

const Routes = () => {
  const [organization, setOrganization] = useState('Loading...');
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCreateRouteModal, setShowCreateRouteModal] = useState(false);
  const [showDeleteRouteModal, setShowDeleteRouteModal] = useState(false);
  const [deleteRouteModalJsx, setDeleteRouteModalJsx] = useState(null);
  const [showUpdateRouteModal, setShowUpdateRouteModal] = useState(false);
  const [updateRouteModalJsx, setUpdateRouteModalJsx] = useState(null);

  useEffect(() => {
    document.body.className = 'pinkBackground';
  }, []);

  useEffect(() => {
    axios
      .get('/routes', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setOrganization(res.data.data.organization);
          setRoutes([...res.data.data.routes]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createRouteModalOnclickHandler = () => {
    setShowCreateRouteModal(true);
  };

  const onRouteUpdated = (route) => {
    console.log(route);
    setRoutes(routes.map((r) => (r.id === route.id ? route : r)));
    setShowUpdateRouteModal(false);
  };

  useEffect(() => {
    console.log(updateRouteModalJsx);
  }, [updateRouteModalJsx]);

  const updateRouteModalOnclickHandler = (id) => {
    const route = routes.find((route) => route.id === id);
    setShowUpdateRouteModal(true);
    setUpdateRouteModalJsx(
      <UpdateRouteModal
        setShowModal={setShowUpdateRouteModal}
        successHandler={onRouteUpdated}
        drivingRoute={route}
      />
    );
  };

  const deleteRouteModalOnclickHandler = (id) => {
    setShowDeleteRouteModal(true);
    setDeleteRouteModalJsx(
      <DeleteRouteModal
        id={id}
        routes={routes}
        setShowModal={setShowDeleteRouteModal}
        successHandler={onRouteDeleted}
      />
    );
  };

  const onNewRouteCreated = (route) => {
    setRoutes([...routes, route]);
  };

  const onRouteDeleted = (id) => {
    setRoutes(routes.filter((route) => route.id !== id));
  };

  const routeJsx = routes.map((route) => (
    <Route
      className={classes.fullHeight}
      updateRouteHandler={updateRouteModalOnclickHandler}
      deleteRouteHandler={deleteRouteModalOnclickHandler}
      key={route.id}
      {...route}
    />
  ));

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <FlexboxContainerHeader>
                <HeadingFlexItem>
                  <h1>Routes</h1>
                  <GrayText>{organization}</GrayText>
                </HeadingFlexItem>
                <HeadingFlexItem>
                  <GreenButton>Create a Route</GreenButton>
                </HeadingFlexItem>
              </FlexboxContainerHeader>
              <Border />
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <FlexboxContainerHeader>
              <HeadingFlexItem>
                <h1>Routes</h1>
                <GrayText>{organization}</GrayText>
              </HeadingFlexItem>
              <HeadingFlexItem>
                <GreenButton onClick={createRouteModalOnclickHandler}>
                  Create a Route
                </GreenButton>
              </HeadingFlexItem>
            </FlexboxContainerHeader>
            <Border />
            <ThreeColumnGrid>{routeJsx}</ThreeColumnGrid>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
      {showCreateRouteModal && (
        <CreateRouteModal
          setShowModal={setShowCreateRouteModal}
          successHandler={onNewRouteCreated}
        />
      )}
      {showDeleteRouteModal && deleteRouteModalJsx}
      {showUpdateRouteModal && updateRouteModalJsx}
    </Fragment>
  );
};

export default Routes;
