import React from 'react'

import classes from './Heading.module.css'

const Border = () => {
  return (
    <div className={classes.border} />
  )
}

export default Border