import React, { useState, useEffect } from 'react'

import fileDownload from 'js-file-download'

import { getPreviousMonth, convertDateToMMDDYYYY } from '../../../../utils'

import BlueResourceBox from '../../../../Components/UI/BlueResourceBox/BlueResourceBox'

import axios from '../../../../axios'

import classes from './LiftStationForm.module.css'

const LiftStationForm = ({ options, setAlert}) => {
  const [systemId, setSystemId] = useState(-1)
  const [month, setMonth] = useState('')
  const [prepareReport, setPrepareReport] = useState(true)

  useEffect(() => {
    console.log(options)
  }, [])
  const prepareReportHandler = e => {
    e.preventDefault()
    if (systemId === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please select a system.'}])
    } else {
      if (month === '') {
        setAlert([])
        setAlert(alert => [...alert, {id: 5, type: 'warning', message: 'Please select a month.'}])
        // Generate Monthly Operating Report
      } else {
        setAlert([])
        // Generate Monthly Operating Report
        const monthDate = convertDateToMMDDYYYY(month)
        axios.get(`/lift-stations/systems/${systemId}/date/${monthDate}/prepare`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        }).then(res => {
          setAlert([])
          setAlert(alert => [...alert, {id: 1, type: 'success', message: 'Your report has been prepared. You may download it now.'}])
          setPrepareReport(false)
          }).catch(err => {
          setAlert(alert => [...alert, {id: 6, type: 'warning', message: err.response.data.message }])
        })
      }
    }
  }

  const downloadReportHandler = e => {
    e.preventDefault()
    if (systemId === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please select a system.'}])
    } else {
      if (month === '') {
        setAlert([])
        setAlert(alert => [...alert, {id: 5, type: 'warning', message: 'Please select a month.'}])
        // Generate Monthly Operating Report
      } else {
        setAlert([])
        // Generate Monthly Operating Report
        const monthDate = convertDateToMMDDYYYY(month)
        axios.get(`/lift-stations/systems/${systemId}/date/${monthDate}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          responseType: 'blob'
        }).then(res => {
          fileDownload(res.data, `LiftStationReport_${monthDate}.xlsx`)
          setPrepareReport(true)
          }).catch(err => {
          setAlert(alert => [...alert, {id: 6, type: 'warning', message: err.response.data.message }])
        })
      }
    }
  }

  const selectChangeHandler = e => {
    setSystemId(e.target.value)
  }

  const monthChangeHandler = e => {
    setMonth(e.target.value)
  }

  const cancelBtnHandler = e => {
    setPrepareReport(true)
    setAlert([])
  }

  const maxDate = getPreviousMonth(new Date())

  return (
    <BlueResourceBox className={classes.setWidth}>
      <form onSubmit={prepareReport ? prepareReportHandler : downloadReportHandler}>
        <label className={classes.label} htmlFor="system">System</label>
        <select disabled={!prepareReport} className={classes.select} name="system" id="system" onChange={selectChangeHandler}>
          <option key={-1} value={-1}>Select a system</option>
          {options}
        </select>
        <label className={classes.label} htmlFor="month">Month</label>
        <input disabled={!prepareReport} className={classes.month} type="month" name="month" id="month" value={month} onChange={monthChangeHandler} max={maxDate} />
        <div className={classes.buttonContainer}>
          <button className={classes.button} type="submit">{prepareReport ? 'Prepare Report' : 'Download Report'}</button>
          {!prepareReport && <button onClick={cancelBtnHandler} className={'btn cancelBtn'}>Cancel</button>}
        </div>
      </form>
    </BlueResourceBox>
  )
}

export default LiftStationForm