import React, { useState, useEffect } from 'react'

import { formatPhoneNumber } from '../../utils'

import classes from './LoginForm.module.css'
import CompleteCenter from '../../Components/UI/CompleteCenter'

const LoginForm = ({ onSubmitPhone, onSubmitPin, displayPhone, errorMessage }) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [pinNumber, setPinNumber] = useState('')
  const [navigate, setNavigate] = useState(false)

  const pinChangeHandler = e => {
    setPinNumber(e.target.value.replace(/\D/g, ''))
  }

  const phoneChangeHandler = e => {
    setPhoneNumber(formatPhoneNumber(e.target.value))
  }

  const pinFormSubmitHandler = e => {
    e.preventDefault()
    const pin = pinNumber
    setPinNumber('')
    onSubmitPin(pin)
  }


  const phoneFormSubmitHandler = e => {
    e.preventDefault()
    const nakedPhone = phoneNumber
                        .replace('(', '')
                        .replace(')', '')
                        .replace('-', '')
                        .replace(' ', '')
    const intPhoneNumber = `+1${nakedPhone}`
    setPhoneNumber('')
    onSubmitPhone(intPhoneNumber)
  }

  if (displayPhone) {
    return (
        <form onSubmit={phoneFormSubmitHandler}>
          <input
              onChange={phoneChangeHandler}
              className={classes.phoneInput}
              type="tel"
              name="phone"
              placeholder="Mobile Number"
              maxLength='14'
              value={phoneNumber}
          />
          <div className={classes.errorMessage}>{errorMessage}</div>
          <CompleteCenter>
            <button
                type='submit'
                className={classes.blueBtnLoginForm}
                disabled={phoneNumber.length !== 14}>
              Submit
            </button>
          </CompleteCenter>
        </form>
    )
  } else {
    return (
        <form onSubmit={pinFormSubmitHandler}>
          <input
              onChange={pinChangeHandler}
              className={classes.phoneInput}
              inputMode='numeric'
              name='pin'
              placeholder='PIN Number'
              maxLength='6'
              value={pinNumber}
          />
          <div className={classes.errorMessage}>{errorMessage}</div>
          <CompleteCenter>
            <button
              type='submit'
              className={classes.blueBtnLoginForm}
              disabled={pinNumber.length < 6}>Submit
            </button>
          </CompleteCenter>
        </form>
    )
  }


}

export default LoginForm