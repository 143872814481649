import React, { useState, useEffect } from 'react'

import fileDownload from 'js-file-download'

import { getPreviousMonth, convertDateToMMDDYYYY } from '../../../utils'

import BlueResourceBox from '../../../Components/UI/BlueResourceBox/BlueResourceBox'

import axios from '../../../axios'

import classes from '../LiftStation/LiftStationForm/LiftStationForm.module.css'

const ResidualForm = ({ options, setAlert }) => {
  const [year, setYear] = useState(-1)
  const [prepareReport, setPrepareReport] = useState(true)

  const prepareReportHandler = e => {
    e.preventDefault()
    if (year === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please Select a Year.'}])
    } else {
      setAlert([])
      // Prepare Residual Report
      axios.get(`/residual/year/${year}/prepare`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => {
        setAlert([])
        setAlert(alert => [...alert, {id: 1, type: 'success', message: 'Your report has been prepared. You may download it now.'}])
        setPrepareReport(false)
      }).catch(err => {
        setAlert([...alert, {id: 6, type: 'warning', message: err.response.data.message}])
      })
    }
  }

  const downloadReportHandler = e => {
    e.preventDefault()
    if (year === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please Select a Year' }])
    } else {
      setAlert([])
      // Generate Residual Report
      axios.get(`/residual/year/${year}/download`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'arraybuffer'
      }).then(res => {
        setAlert([])
        setAlert(alert => [...alert, {id: 1, type: 'primary', message: 'Downloading Annual Chlorine Residual Report...'}])
        const headerLine = res.headers['content-disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        fileDownload(blob, fileName)
        setAlert(alerts => [...alerts, {id: 1, type: 'primary', message: 'Thank you for using LiftStation.cloud'}])
        setPrepareReport(true)
      }).catch(err => {
        setAlert(alert => [...alert, {id: 6, type: 'warning', message: 'There was an error.'}])
      })
    }
  }

  const selectChangeHandler = e => {
    setYear(e.target.value)
  }

  const cancelBtnHandler = () => {
    setPrepareReport(true)
    setAlert([])
  }

  return (
    <BlueResourceBox className={classes.setWidth}>
      <form onSubmit={prepareReport? prepareReportHandler: downloadReportHandler}>
        <label className={classes.label} htmlFor={'year'}>Year</label>
        <select disabled={!prepareReport} className={classes.select} id={'year'} name={'year'} onChange={selectChangeHandler} value={year}>
          <option key={-1} value={-1}>Please Select a Year</option>
          {options}
        </select>
        <div className={classes.buttonContainer}>
          <button className={'btn confirmBtn'} type={'submit'}>{prepareReport ? 'Prepare Report' : 'Download Report'}</button>
          {!prepareReport && <button onClick={cancelBtnHandler} className={'btn cancelBtn'}>Cancel</button> }
        </div>
      </form>
    </BlueResourceBox>
  )

}

export default ResidualForm