import React, { Fragment, useEffect, useState } from 'react';

import Container from '../../../Components/UI/Container';
import CompleteCenter from '../../../Components/UI/CompleteCenter';
import WhiteBackground from '../../../Components/UI/WhiteBackground/WhiteBackground';
import Spinner from '../../../Components/UI/Spinner/Spinner';
import SdwPermit from './SdwPermit';
import GreenButton from '../../../Components/UI/GreenButton/GreenButton';
import ThreeColumnGrid from '../../../Components/UI/ThreeColumnGrid/ThreeColumnGrid';
import FlexboxContainerHeader from '../../../Components/UI/Heading/FlexboxContainerHeader';
import HeadingFlexItem from '../../../Components/UI/Heading/HeadingFlexItem';
import Border from '../../../Components/UI/Heading/Border';

import axios from '../../../axios';

import Breadcrumb from '../../../Components/UI/Heading/Breadcrumb';
import CreateSdwPermitModal from './CreateSdwPermitModal';
import DeleteSdwPermitModal from './DeleteSdwPermitModal';
const GaSdwPermits = () => {
  const [loading, setLoading] = useState(true);
  const [permits, setPermits] = useState([]);
  const [showCreatePermitModal, setShowCreatePermitModal] = useState(false);
  const [showDeletePermitModal, setShowDeletePermitModal] = useState(false);
  const [deletePermitModalJsx, setDeletePermitModalJsx] = useState(null);

  useEffect(() => {
    document.body.className = 'pinkBackground';
  }, []);

  useEffect(() => {
    axios
      .get('/permits/ga/sdw', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setPermits([...res.data.permits]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const createSdwPermitHandler = (sdwPermit) => {
    setPermits((permits) => [...permits, sdwPermit]);
  };

  const createPermitOnClick = () => {
    setShowCreatePermitModal(true);
  };

  const onPermitDeleted = (id) => {
    setPermits(permits.filter((permit) => permit.id !== id));
  };

  const deletePermitOnClickHandler = (id) => {
    setShowDeletePermitModal(true);
    setDeletePermitModalJsx(
      <DeleteSdwPermitModal
        id={id}
        setShowModal={setShowDeletePermitModal}
        sdwPermits={sdwPermits}
        successHandler={onPermitDeleted}
      />
    );
  };

  const sdwPermits = permits.map((permit) => (
    <SdwPermit
      key={permit.id}
      {...permit}
      deleteHandler={deletePermitOnClickHandler}
    />
  ));

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <FlexboxContainerHeader>
                <HeadingFlexItem>
                  <h1>Safe Drinking Water Permits</h1>
                  <Breadcrumb
                    parentName='Permits'
                    parentUrl='/permits'
                    name='Georgia Safe Drinking Water Permits'
                  />
                </HeadingFlexItem>
                <HeadingFlexItem>
                  <GreenButton>Add a Permit</GreenButton>
                </HeadingFlexItem>
              </FlexboxContainerHeader>
              <Border />
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <FlexboxContainerHeader>
              <HeadingFlexItem>
                <h1>Safe Drinking Water Permits</h1>
                <Breadcrumb
                  parentName='Permits'
                  parentUrl='/permits'
                  name='Georgia Safe Drinking Water Permits'
                />
              </HeadingFlexItem>
              <HeadingFlexItem>
                <GreenButton onClick={createPermitOnClick}>
                  Add a Permit
                </GreenButton>
              </HeadingFlexItem>
            </FlexboxContainerHeader>
            <Border />
            <ThreeColumnGrid>{sdwPermits}</ThreeColumnGrid>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
      {showCreatePermitModal && (
        <CreateSdwPermitModal
          setShowModal={setShowCreatePermitModal}
          title={'Create a SDW Permit'}
          successHandler={createSdwPermitHandler}
        />
      )}
      {showDeletePermitModal && deletePermitModalJsx}
    </Fragment>
  );
};

export default GaSdwPermits;
