import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import emailIcon from '../../img/email-icon.png'
import blueCheckbox from '../../img/blue-checkbox.png'

// css classes
import classes from './dashboard.module.css'

const EmailVerifiedBox = ({ verified, resendVerificationEmail, message }) => {
  return (
    <WhiteBox className={classes.minHeight}>
      <img height={98} src={verified ? blueCheckbox : emailIcon} alt="email" />
      <h3>{verified ? 'Email Verified' : 'Email Not Verified'}</h3>
      <p>{message}</p>
      <div className={classes.buttonContainer}>
        {verified ? (
          // these buttons appear if the email is verified
          <Fragment>
            <a target={'_blank'} href={'https://docs.liftstation.cloud'} className={classes.button}>Read Documentation</a>
            <a href={'mailto:brian@consoli.tech'} className={`${classes.button} ${classes.mt25}`}>Email the Developer</a>
          </Fragment>
          ) : (
          // these buttons appear if the email is not verified
          <Fragment>
          <button onClick={resendVerificationEmail} className={classes.button}>Resend Verification Email</button>
          <Link className={`${classes.button} ${classes.mt25}`} to={'/users'}>Change Your Email Address</Link>
          </Fragment>
          )}
      </div>
    </WhiteBox>
  )
}

export default EmailVerifiedBox