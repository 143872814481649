import React, { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import Container from "../../../Components/UI/Container";
import CompleteCenter from "../../../Components/UI/CompleteCenter";
import AlertDialog from "../../../Components/UI/AlertDialog/AlertDialog";
import axios from "../../../axios";
import Spinner from "../../../Components/UI/Spinner/Spinner";

import WhiteBackground from "../../../Components/UI/WhiteBackground/WhiteBackground";
import WellForm from "./WellForm";

import Spreadsheet from "react-spreadsheet";

import classes from './wellReadings.module.css'

const WellReadings = () => {
  const [loading, setLoading] = useState(false)
  const [wells, setWells] = useState([])
  const [options, setOptions] = useState([])
  const [spreadsheetData, setSpreadsheetData] = useState([])
  const [displayForm, setDisplayForm] = useState(true)
  const [alerts , setAlerts] = useState([{
    id: 1,
    type: 'primary',
    message: 'To view the readings of a well and edit them, please select the well and the month.'
  }])
  const [wellName, setWellName] = useState('')
  const [month, setMonth] = useState('')
  const [wellId, setWellId] = useState('')
  const [date, setDate] = useState('')

  const alertMessages = alerts.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)

  useEffect(() => {
    document.body.className = 'pinkBackground'
  })

  useEffect(() => {
    axios.get('/systems/wells', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      setWells(res.data.systems)
      setLoading(false)
    }).catch(err => {
      console.log(err)
    })
  }, [])

  useEffect(() => {
    for (let i = 0; i < wells.length; i++) {
      setOptions(prevOptions => [...prevOptions, <option key={`sys-${wells[i].id}`} value={0}>{`----${wells[i].name}---`}</option>])
      for (let j = 0; j < wells[i].wells.length; j++) {
        setOptions(prevOptions => [...prevOptions, <option key={wells[i].wells[j].id} value={wells[i].wells[j].id}>{wells[i].wells[j].name}</option>])
      }
    }
  }, [wells])

  const convertMonthFromNumber = (month) => {
    switch (month) {
      case '01':
        return 'January'
      case '02':
        return 'February'
      case '03':
        return 'March'
      case '04':
        return 'April'
      case '05':
        return 'May'
      case '06':
        return 'June'
      case '07':
        return 'July'
      case '08':
        return 'August'
      case '09':
        return 'September'
      case '10':
        return 'October'
      case '11':
        return 'November'
      case '12':
        return 'December'
      default:
        return ''
    }
  }

  const wellFormChangeHandler = (wellId, date) => {
    setWellId(wellId)
    setDate(date)
    setLoading(true)
    axios.get(`/wells/${wellId}/readings/${date}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      setDisplayForm(false)
      setLoading(false)
      setWellName(res.data.well)
      const [month, year] = res.data.reportMonth.split('-')
      const convertedMonth = convertMonthFromNumber(month)
      setMonth(`${convertedMonth} ${year}`)
      setSpreadsheetData(res.data.data)
      setAlerts([{
        id: 1,
        type: 'primary',
        message: 'To update a pump or chlorine residual reading, please double click on the cell and enter the new value into the cell. Once the value is entered, type the word "save" into the cell, without adding quotes.'
      }])
    }).catch(err => {
      setAlerts(alerts => [...alerts, {id: 2, type: 'warning', message: err.response.data.message}])
      setLoading(false)
    })
  }

  const onCellCommitHandler = (prevCell, nextCell, lastChanged) => {
    console.log(prevCell, nextCell, lastChanged)
  }



  const spreadsheetDataChangeHandler = (data) => {
    for (let i = 1; i < data.length; i++) {
      const pump = data[i][1].value
      const chlorine = data[i][2].value ? data[i][2].value : null
      const id = data[i][1].id
      const readingDate = data[i][0].value
      // This is a POST request to enter new data
      if (id === null) {
        if (pump.toString().includes('save')) {
          setLoading(true)
          console.log(pump.toString().replace('save', ''))
          axios.post(`/wells/${wellId}/readings/${date}`, {
            meterReading: pump.toString().replace('save', ''),
            chlorineResidual: chlorine,
            readingDate
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }).then(res => {
            setAlerts(alerts => [ {id: 1, type: 'success', message: 'The updates you made were successfully saved.'} ])
            setLoading(false)
            setSpreadsheetData(res.data.data)
          }).catch(err => {
            console.log(err)
            setAlerts(alerts => [{id: 2, type: 'warning', message: 'There was an error updating the reading. Please try again.'}])
            setLoading(false)
          })
        }
        if (chlorine !== null) {
          if (chlorine.toString().includes('save')) {
            setLoading(true)
            axios.post(`/wells/${wellId}/readings/${date}`, {
              meterReading: pump,
              chlorineResidual: chlorine.replace('save', ''),
              readingDate
            }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }).then(res => {
              setAlerts(alerts => [ {id: 1, type: 'success', message: 'The updates you made were successfully saved.'} ])
              setLoading(false)
              setSpreadsheetData(res.data.data)
            }).catch(err => {
              setAlerts(alerts => [{id: 2, type: 'warning', message: 'There was an error updating the reading. Please try again.'}])
              setLoading(false)
            })
          }
        }

      } else {
        if (pump.toString().includes('save')) {
          setLoading(true)
          axios.put(`/wells/${wellId}/readings/${date}`, {
            meterReading: pump.replace('save', ''),
            readingId: id,
            chlorineResidual: chlorine
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }).then(res => {
            setAlerts(alerts => [{id: 1, type: 'success', message: 'The updates you made were successfully saved.'}])
            setLoading(false)
            setSpreadsheetData(res.data.data)
          }).catch(err => {
            setAlerts(alerts => [{id: 1, type: 'warning', message: 'There was an error updating the reading. Please try again.'}])
            setLoading(false)
          })
        }
        if (chlorine !== null) {
          if (chlorine.toString().includes('save')) {
            setLoading(true)
            axios.put(`/wells/${wellId}/readings/${date}`, {
              meterReading: pump,
              readingId: id,
              chlorineResidual: chlorine.replace('save', '')
            }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            }).then(res => {
              setAlerts(alerts => [{id: 1, type: 'success', message: 'The changes you made have been updated.'}])
              setLoading(false)
              setSpreadsheetData(res.data.data)
            }).catch(err => {
              setAlerts(alerts => [{id: 1, type: 'warning', message: 'There was an error updating the reading. Please try again.'}])
              setLoading(false)
            })
          }
        }
      }
    }
  }

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    )
  }



  if (displayForm) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <h1 className={'text-center'}>Edit Well Readings</h1>
              <CompleteCenter>
                <div>
                  <Link className={'breadCrumbLink'} to={'/readings'}>Readings</Link>
                  <span className={'breadcrumb-separator'}>/</span>
                  <span className={'breadcrumb-separator'}>Well Readings</span>
                </div>
              </CompleteCenter>
              <div className={'breadcrumbBorderBottom'} />
              {alertMessages}
              <WellForm options={options} setAlerts={setAlerts} handler={wellFormChangeHandler} />
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    )
  }

  if (!displayForm) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <h1 className={'text-center'}>Edit Well Readings</h1>
              <CompleteCenter>
                <div>
                  <Link className={'breadCrumbLink'} to={'/readings'}>Readings</Link>
                  <span className={'breadcrumb-separator'}>/</span>
                  <span className={'breadcrumb-separator'}>Well Readings</span>
                </div>
              </CompleteCenter>
              <div className={'breadcrumbBorderBottom'} />
              {alertMessages}
              <button className={'btn cancelBtn'} onClick={() => setDisplayForm(true)}>Back</button>
              <h2 className={'text-center'}>{wellName}</h2>
              <h3 className={'text-center'}>{month}</h3>
              <CompleteCenter>
                <Spreadsheet
                  className={classes.spreadSheet}
                  data={spreadsheetData}
                  onChange={spreadsheetDataChangeHandler}
                  onCellCommit={onCellCommitHandler}
                />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    )
  }


}

export default WellReadings