import permit from './Pages/Permits/Permit';

export const formatPhoneNumber = (phone) => {
  phone = phone.replace(/\D/g, '');
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    phone = `(${match[1]}${match[2] ? ') ' : ''}${match[2]}${
      match[3] ? '-' : ''
    }${match[3]}`;
  }
  return phone;
};

export const loggedIn = () => {
  if (localStorage.getItem('token')) {
    return localStorage.getItem('token') !== undefined;
  }
};

export const logUserOut = () => {
  window.localStorage.clear();
};

export const removePhoneNumberFormatting = (phone) => {
  phone = phone
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '');
  phone = `+1${phone}`;
  return phone;
};

/************************************************************************************
 * @ params {date} current date
 * @ returns {date} current date minus 1 month
 * @ desc: This function will return the current date minus 1 month in the format of
 * YYYY-MM
 ***********************************************************************************/
export const getPreviousMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const previousMonth = new Date(year, month - 1, 1);
  return previousMonth.toISOString().split('T')[0].slice(0, -3);
};

export const getCurrentMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const theDate = new Date(year, month, 1);
  return theDate.toISOString().split('T')[0].slice(0, -3);
};

/************************************************************************************
 * @ params {date} dateOfReport
 * @ returns {date} dateOfReort
 * @ desc: Converts a date in YYYY-MM format to MM-DD-YYYY
 ***********************************************************************************/
export const convertDateToMMDDYYYY = (dateOfReport) => {
  const [year, month] = dateOfReport.split('-');
  return `${month}-01-${year}`;
};

export const routeInputChangeHandler = (e, setRouteName) => {
  const routeNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'Route name must be between 3 and 21 characters.',
  };

  const routeNameValid = {
    id: 2,
    type: 'success',
    message: 'Route name is valid.',
  };

  setRouteName(e.target.value);
  if (e.target.value.length < 3 || e.target.value.length > 21) {
    setAlert((alerts) => {
      return alerts.filter((alert) => alert.id !== 2);
    });
    setAlert((alerts) => {
      return alerts.concat(routeNameInvalid);
    });
  }
  if (e.target.value.length >= 3 && e.target.value.length <= 21) {
    setAlert((alerts) => {
      return alerts.filter((alert) => alert.id !== 2);
    });
    setAlert((alerts) => alerts.concat(routeNameValid));
  }
};
