import React from 'react'

import classes from './Heading.module.css'
import {Link} from "react-router-dom";

const Breadcrumb = ({parentName, parentUrl, name}) => {
  return (
    <div>
      <Link className={classes.breadCrumbLink} to={parentUrl}>{parentName}</Link>
      <span className={'breadcrumb-separator'}>/</span>
      <span className={'breadcrumb-separator'}>{name}</span>
    </div>
  )
}

export default Breadcrumb