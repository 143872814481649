import React, { Fragment, useEffect } from 'react'

// Components
import Container from '../../Components/UI/Container'
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid'
import CompleteCenter from '../../Components/UI/CompleteCenter'
import MonthlyOperatingReportBox from './MonthlyOperatingReportBox'
import GroundWaterWithdrawalReportBox from './GroundWaterWithdrawalReportBox'
import WaterLossReportsBox from './WaterLossReportsBox'
import MonthlyLiftStationReportBox from './MonthlyLiftStationReportBox'
import LiftStationRainfallBox from './LiftStationRainfallBox'


import './Reports.css'
import classes from './Reports.module.css'
import ChlorineResidualBox from "./ChlorineResidualBox";

const Reports = () => {
  useEffect(() => {
    document.body.className = 'reportsPage'
  })

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <h1 className={classes.visibleHeader}>Reports</h1>
        </CompleteCenter>
        <ThreeColumnGrid>
          <MonthlyOperatingReportBox />
          <GroundWaterWithdrawalReportBox />
          <WaterLossReportsBox />
          <MonthlyLiftStationReportBox />
          <ChlorineResidualBox />
        </ThreeColumnGrid>
      </Container>
    </Fragment>
  )
}

export default Reports
