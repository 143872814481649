import React from 'react'

import BlueResourceBox from '../../Components/UI/BlueResourceBox/BlueResourceBox'
import Phone from './Phone'
import TechnicianAndOperatorAccess from './TechnicianAndOperatorAccess'
import Email from './Email'
import OperatorClass from './OperatorClass'
import Route from './Route'
import Buttons from '../../Components/UI/TitleAndContent/Buttons'
import greenCheckbox from '../../img/accessRoute.png'
import redX from '../../img/noAccessRoute.png'

import classes from './Users.module.css'


const User = ({id, firstName, lastName, phone, technicianAccess, operatorAccess, operatorClass, email, emailVerified, routes, deleteUserHandler, updateUserHandler, technicianPermissions}) => {
  if (technicianAccess && operatorAccess) {
    const routesList = routes.map(route => <Route key={route.id} {...route} />)
    const changeWellMeterIcon = technicianPermissions.changeWellMeter ? greenCheckbox : redX
    const changeLiftStationDial = technicianPermissions.changeLiftStationDial ? greenCheckbox : redX
    const setGPSLocationWell = technicianPermissions.setGPSLocationWell ? greenCheckbox : redX
    const setGPSLocationLiftStation = technicianPermissions.setGPSLocationLiftStation ? greenCheckbox : redX
    const accessServiceLineInventory = technicianPermissions.accessServiceLineInventory ? greenCheckbox : redX
    return (
      <BlueResourceBox className={classes.fullHeight}>
        <div className={classes.flexContainerColumn}>
          <div>
            <h2 className={classes.centerText}>{`${firstName} ${lastName}`}</h2>
            <Phone>{phone}</Phone>
            <TechnicianAndOperatorAccess
              technicianAccess={technicianAccess}
              operatorAccess={operatorAccess}
            />
            <Email
              email={email}
              emailVerified={emailVerified}
            />
            <OperatorClass
              operatorClass={operatorClass}
            />
          </div>
          <div>
            <h3>Technician Permissions:</h3>
            <ul>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={changeWellMeterIcon} alt={'route access indicator'}/>
                Change Well Meter
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={changeLiftStationDial} alt={'route access indicator'}/>
                Change Lift Station Dial
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={setGPSLocationWell} alt={'route access indicator'}/>
                Set GPS Location Well
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={setGPSLocationLiftStation} alt={'route access indicator'}/>
                Set GPS Location Lift Station
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={accessServiceLineInventory} alt={'route access indicator'}/>
                Access Service Line Inventory
              </li>
            </ul>
            <h3>Routes:</h3>
            <ul>
              {routesList}
            </ul>
            <Buttons
                id={id}
                deleteHandler={deleteUserHandler}
                successHandler={updateUserHandler}
            />
          </div>
        </div>

      </BlueResourceBox>
    )
  }
  if (!technicianAccess && operatorAccess) {
    return (
      <BlueResourceBox className={classes.fullHeight}>
        <div className={classes.flexContainerColumn}>
          <div>
            <h2 className={classes.centerText}>{`${firstName} ${lastName}`}</h2>
            <Phone>{phone}</Phone>
            <TechnicianAndOperatorAccess
              technicianAccess={technicianAccess}
              operatorAccess={operatorAccess}
            />
            <Email
              email={email}
              emailVerified={emailVerified}
            />
            <OperatorClass
              operatorClass={operatorClass}
            />
          </div>
          <Buttons
            id={id}
            deleteHandler={deleteUserHandler}
            successHandler={updateUserHandler}
          />
        </div>
      </BlueResourceBox>
    )
  }

  if (technicianAccess && !operatorAccess) {
    const changeWellMeterIcon = technicianPermissions.changeWellMeter ? greenCheckbox : redX
    const changeLiftStationDial = technicianPermissions.changeLiftStationDial ? greenCheckbox : redX
    const setGPSLocationWell = technicianPermissions.setGPSLocationWell ? greenCheckbox : redX
    const setGPSLocationLiftStation = technicianPermissions.setGPSLocationLiftStation ? greenCheckbox : redX
    const accessServiceLineInventory = technicianPermissions.accessServiceLineInventory ? greenCheckbox : redX
    const routesList = routes.map(route => <Route key={route.id} {...route} />)

    return (
      <BlueResourceBox className={classes.fullHeight}>
        <div className={classes.flexContainerColumn}>
          <div>
            <h2 className={classes.centerText}>{`${firstName} ${lastName}`}</h2>
            <Phone>{phone}</Phone>
            <TechnicianAndOperatorAccess
              technicianAccess={technicianAccess}
              operatorAccess={operatorAccess}
            />
          </div>
          <div>
            <h3>Technician Permissions:</h3>
            <ul>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={changeWellMeterIcon} alt={'route access indicator'}/>
                Change Well Meter
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={changeLiftStationDial} alt={'route access indicator'}/>
                Change Lift Station Dial
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={setGPSLocationWell} alt={'route access indicator'}/>
                Set GPS Location Well
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={setGPSLocationLiftStation} alt={'route access indicator'}/>
                Set GPS Location Lift Station
              </li>
              <li className={classes.listStyle}>
                <img className={classes.routeIcon} src={accessServiceLineInventory} alt={'route access indicator'}/>
                Access Service Line Inventory
              </li>
            </ul>
            <h3>Routes:</h3>
            <ul>
              {routesList}
            </ul>
            <Buttons
              id={id}
              deleteHandler={deleteUserHandler}
              successHandler={updateUserHandler}
            />
          </div>
        </div>
      </BlueResourceBox>
    )
  }
}

export default User