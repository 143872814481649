import React, { useState } from 'react'


import Modal from '../../Components/UI/Modal/Modal'
import UserForm from './UserForm'
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog'

const CreateUserModal = ({ setShowModal, scrollToTop, drivingRoutes, successHandler, innerRef }) => {

  const [alerts, setAlerts] = useState([{
    id: 1,
    type: 'primary',
    message: 'Fill out the form to add a new user.'
  }])

  const alertsJsx = alerts.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)

  return (
      <Modal title={'Create a New User'}
             setShowModal={setShowModal}
             innerRef={innerRef}
      >
        {alertsJsx}
      <UserForm
        user={null}
        scrollToTop={scrollToTop}
        setShowModal={setShowModal}
        drivingRoutes={drivingRoutes}
        setAlerts={setAlerts}
        successHandler={successHandler}
      />
      </Modal>
    )
}

export default CreateUserModal