import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import liftStationPump from '../../img/lift-station-pump.png'

// css classes
import classes from './dashboard.module.css'

const ChangeLiftStationPumpBox = () => {
  return (
    <WhiteBox className={classes.minHeight}>
      <img height={98} src={liftStationPump} alt="lift station pump" />
      <h3>Change Lift Station Pump</h3>
      <p>
        Change the pump for a lift station. Click View Documentation to learn more.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/change-lift-station-pump'} className={classes.button}>Change Pump</Link>
        <a target={'_blank'} href={'https://docs.liftstation.cloud'} className={`${classes.button} ${classes.mt25}`}>View Documentation</a>
      </div>
    </WhiteBox>
  )
}

export default ChangeLiftStationPumpBox