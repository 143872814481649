import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import sewageIcon from '../../img/sewage.png'

const LiftStationReadingsBox = () => {
  return (
    <WhiteBox>
      <img height={98} src={sewageIcon} alt="sewage-icon" />
      <h3>Lift Station Readings</h3>
      <p>
        View lift station readings here.
      </p>
      <div className={'buttonContainer'}>
        <Link to={'/readings/stations'} className={'boxButton'}>View Readings</Link>
      </div>
    </WhiteBox>
  )
}

export default LiftStationReadingsBox