import React, { useEffect, Fragment } from 'react'

import Container from '../../Components/UI/Container'
import RegisterBox from './RegisterBox'

import './Register.css'

const Register = () => {
  useEffect(() => {
    document.body.className = 'registerPage'
  })

  return (
      <Fragment>
        <Container>
          <RegisterBox />
        </Container>
      </Fragment>
  )
}

export default Register