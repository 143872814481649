import React, { useState, useEffect } from 'react';
import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import axios from '../../axios';

import SystemForm from './SystemForm';

const CreateSystemModal = ({
  setShowModal,
  title,
  successHandler,
  permits,
}) => {
  const [systemName, setSystemName] = useState('');
  const [permitId, setPermitId] = useState('');
  const createSystemAlert = {
    id: 1,
    type: 'primary',
    message: 'To create a system, please enter a name for the system.',
  };

  const systemNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'System name must be between 2 and 25 characters.',
  };

  const [alert, setAlert] = useState([createSystemAlert, systemNameInvalid]);

  const permitIdHandler = (e) => {
    setPermitId(e.target.value);
  };
  const systemNameValid = {
    id: 2,
    type: 'success',
    message: 'System name is valid.',
  };
  const systemNameHandler = (e) => {
    setSystemName(e.target.value);
    if (e.target.value.length < 2 || e.target.value.length > 25) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(systemNameInvalid));
    }
    if (e.target.value.length >= 2 && e.target.value.length <= 25) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(systemNameValid));
    }
  };
  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));
  const createSystemHandler = (e) => {
    e.preventDefault();
    if (systemName.length >= 2 || systemName.length <= 25) {
      console.log('fired');
      axios
        .post(
          '/systems',
          {
            name: systemName,
            permitId: permitId,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            successHandler(res.data.system);
            setShowModal(false);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          if (err.response.data.message) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => [
              ...alerts,
              { id: 2, type: 'danger', message: err.response.data.message },
            ]);
          }
        });
    }
  };
  return (
    <Modal
      setShowModal={setShowModal}
      title={title}
      confirmButtonText={'Create System'}
    >
      {alertsJsx}
      <SystemForm
        setAlert={setAlert}
        setShowModal={setShowModal}
        createSystemHandler={createSystemHandler}
        systemNameHandler={systemNameHandler}
        permitIdHandler={permitIdHandler}
        permits={permits}
        permitId={permitId}
      />
    </Modal>
  );
};

export default CreateSystemModal;
