import React, { useState } from 'react'

import Modal from '../../Components/UI/Modal/Modal'
import UserForm from './UserForm'
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog'

const UpdateUserModal = ({ setShowModal, user, successHandler, scrollToTop, innerRef }) => {
  const [alerts, setAlerts] = useState([{
    id: 1,
    type: 'primary',
    message: 'Fill out the form to update a user.'
  }])

  const alertsJsx = alerts.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)

  return (
    <Modal title={'Update User'}
            setShowModal={setShowModal}
           innerRef={innerRef}
           >
      {alertsJsx}
      <UserForm
        user={user}
        setShowModal={setShowModal}
        successHandler={successHandler}
        setAlerts={setAlerts}
        scrollToTop={scrollToTop}
      />
    </Modal>
  )
}

export default UpdateUserModal