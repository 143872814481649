import React, { useState } from 'react'

import fileDownload from 'js-file-download'

import { getPreviousMonth, convertDateToMMDDYYYY } from '../../../utils'

import BlueResourceBox from '../../../Components/UI/BlueResourceBox/BlueResourceBox'

import axios from '../../../axios'

import classes from '../GaMor/GaMorForm/GaMorForm.module.css'

const GaGwwForm = ({ systems, setAlert }) => {
  const [systemId, setSystemId] = useState(-1)
  const [month, setMonth] = useState('')
  const [prepareReport, setPrepareReport] = useState(true)

  const prepareReportHandler = e => {
    e.preventDefault()
    if (systemId === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please select a system.'}])
    } else {
      if (month === '') {
        setAlert([])
        setAlert(alert => [...alert, {id: 5, type: 'warning', message: 'Please select a month.'}])
      } else {
        setAlert([])
        const monthDate = convertDateToMMDDYYYY(month)
        axios.get(`/gww/system/${systemId}/date/${monthDate}/prepare`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        }).then(res => {
          setAlert([])
          setAlert(alert => [...alert, {id: 1, type: 'success', message: 'Your report has been prepared. You may download it now.'}])
          setPrepareReport(false)
        }).catch(err => {
          setAlert(alert => [...alert, {id: 6, type: 'warning', message: err.response.data.message }])
        })
      }
    }
  }

  const downloadReportHandler = e => {
    e.preventDefault()
      // Generate Groundwater Withdrawal Report
      const monthDate = convertDateToMMDDYYYY(month)
      axios.get(`/gww/system/${systemId}/date/${monthDate}/download`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'arraybuffer'
      }).then(res => {
        setAlert([])
        setAlert(alert => [...alert, {id: 1, type: 'primary', message: 'Downloading Groundwater Withdrawal Report...'}])
        const headerLine = res.headers['content-disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        fileDownload(blob, fileName)
        setAlert(alerts => [...alerts, {id: 1, type: 'primary', message: 'Thank you for using LiftStation.cloud. :)'}])
        setPrepareReport(true)
      }).catch(err => {
        setAlert(alert => [...alert, {id: 6, type: 'warning', message: 'There was an error. Please contact support.' }])
      })
  }

  const systemChangeHandler = e => {
    setSystemId(e.target.value)
  }

  const monthChangeHandler = e => {
    setMonth(e.target.value)
  }

  const maxDate = getPreviousMonth(new Date())

  const cancelHandler = e => {
    setPrepareReport(true)
    setAlert([])
  }

  const options = systems.map(system => <option key={system.id} value={system.id}>{system.name}</option>)

  return (
    <BlueResourceBox className={classes.setWidth}>
      <form onSubmit={prepareReport ? prepareReportHandler : downloadReportHandler}>
        <label className={classes.label} htmlFor="systemId">System</label>
        <select disabled={!prepareReport} className={classes.select} name="systemId" id="systemId" value={systemId} onChange={systemChangeHandler}>
          <option value="-1">Select a system</option>
          {options}
        </select>
        <label className={classes.label} htmlFor={'month'}>Month</label>
        <input disabled={!prepareReport} className={classes.month} id={'month'} onChange={monthChangeHandler} value={month} type={'month'} name={'month'} max={maxDate} />
        <div className={classes.buttonContainer}>
          <button className={'btn confirmBtn'} type={'submit'}>{prepareReport ? 'Prepare Report' : 'Download Report'}</button>
          {!prepareReport && <button onClick={cancelHandler} className={'btn cancelBtn'}>Cancel</button>}
        </div>
      </form>
    </BlueResourceBox>
  )
}

export default GaGwwForm