import React from 'react'

import classes from './Heading.module.css'

const HeadingFlexItem = ({children}) => {
  return (
    <div className={classes.flexItem}>{children}</div>
  )
}

export default HeadingFlexItem