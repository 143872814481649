import React, { useState } from 'react';

import Modal from '../../../Components/UI/Modal/Modal';
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog';

import axios from '../../../axios';

const DeleteSdwPermitModal = ({
  setShowModal,
  id,
  sdwPermits,
  successHandler,
}) => {
  const permitItem = sdwPermits.find((permit) => permit.props.id === id);
  const [sdwPermitNumber, setSdwPermitNumber] = useState(
    permitItem.props.permitNumber
  );
  const [userPermitNumber, setUserPermitNumber] = useState('');
  const [alert, setAlert] = useState([
    {
      id: 1,
      type: 'danger',
      message: `To confirm deletion please copy and paste the permit number in the text field. The permit number is: ${sdwPermitNumber}`,
    },
    {
      id: 2,
      type: 'warning',
      message: 'Sdw permit names do not match',
    },
  ]);

  const onChangeSdwPermitNumber = (e) => {
    e.preventDefault();
    setUserPermitNumber(e.target.value);
    if (e.target.value !== sdwPermitNumber) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => {
        return alerts.concat({
          id: 2,
          type: 'warning',
          message: 'Sdw permit names do not match',
        });
      });
    }
    if (e.target.value === sdwPermitNumber) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => {
        return alerts.concat({
          id: 2,
          type: 'success',
          message: 'Sdw permit names match',
        });
      });
    }
  };

  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.id} type={alert.type} children={alert.message} />
  ));

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (userPermitNumber === sdwPermitNumber) {
      axios
        .delete(`/permits/ga/sdw/${id}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setShowModal(false);
            successHandler(id);
          }
        })
        .catch((err) => {
          setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
          setAlert((alerts) => {
            return alerts.concat({
              id: 2,
              type: 'danger',
              message: err.response.data.message,
            });
          });
        });
    }
  };
  return (
    <Modal setShowModal={setShowModal} title={'Delete SDW Permit'}>
      {alertsJsx}
      <form onSubmit={onSubmitHandler}>
        <label htmlFor='sdwPermitNumber'>SDW Permit Number</label>
        <input
          className={'input'}
          type='text'
          name='sdwPermitNumber'
          id='sdwPermitNumber'
          value={userPermitNumber}
          onChange={onChangeSdwPermitNumber}
          placeholder={sdwPermitNumber}
        />
        <div className={'footer-m'}>
          <button
            className={'btn cancelBtn'}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button type={'submit'} className={'btn confirmBtn'}>
            Delete
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteSdwPermitModal;
