import React from 'react';

import { Link } from 'react-router-dom';

import BlueResourceBox from '../../../../Components/UI/BlueResourceBox/BlueResourceBox';

import classes from '../GaMor.module.css';

const EmailVerification = () => {
  return (
    <BlueResourceBox>
      <h2 className={'text-center'}>Email Verification</h2>
      <p>
        Your email address must be verified to ensure the EPD has the correct
        email address on generated reports.
        <Link className={classes.greenButton} to='/dashboard'>
          Click Here
        </Link>
      </p>
    </BlueResourceBox>
  );
};

export default EmailVerification;
