import React, { useEffect } from 'react'

import {logUserOut} from '../utils'
import {Navigate} from "react-router-dom";

const Logout = () => {
  useEffect(() => {
    logUserOut()
    const goToHome = () => {
      window.location.href = '/'
    }
    goToHome()
  }, [])



  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  )
}

export default Logout