import React from 'react';
import CompleteCenter from '../../Components/UI/CompleteCenter';

import classes from './TwoApps.module.css';
import DataCollectionApp from './DataCollectionApp';

const TwoApps = ({ apps }) => {
  const AppItems = apps.map((app, index) => (
    <DataCollectionApp key={index} {...app} />
  ));

  return (
    <section className={classes.section}>
      <CompleteCenter>
        <h2 className={classes.header2}>Two Data Collection Apps</h2>
      </CompleteCenter>
      <div className={classes.flexContainer}>{AppItems}</div>
    </section>
  );
};

export default TwoApps;
