import React, { useEffect, useState } from 'react';

import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import RouteForm from './RouteForm';

const CreateRouteModal = ({ setShowModal, title, successHandler }) => {
  const createRouteAlert = {
    id: 1,
    type: 'primary',
    message: 'To create a route, please enter a name for the route.',
  };

  const routeNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'Route name must be between 3 and 21 characters.',
  };

  const [alert, setAlert] = useState([createRouteAlert, routeNameInvalid]);

  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));

  return (
    <Modal title={title} setShowModal={setShowModal}>
      {alertsJsx}
      <RouteForm
        setAlert={setAlert}
        setShowModal={setShowModal}
        successHandler={successHandler}
      />
    </Modal>
  );
};

export default CreateRouteModal;
