import React, { useEffect, useState } from 'react';

import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';

import axios from '../../axios';
import classes from '../../Components/UI/Modal/Modal.module.css';
import RouteForm from './RouteForm';

const UpdateRouteModal = ({
  setShowModal,
  title,
  drivingRoute,
  successHandler,
}) => {
  const updateRouteAlert = {
    id: 1,
    type: 'primary',
    message: 'Type the name of the route and press update.',
  };

  const routeNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'Route name must be between 3 and 21 characters.',
  };

  const routeNameValid = {
    id: 2,
    type: 'success',
    message: 'Route name is valid.',
  };

  const [alert, setAlert] = useState([updateRouteAlert, routeNameValid]);
  const [route, setRoute] = useState(drivingRoute);

  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));

  return (
    <Modal setShowModal={setShowModal} title={'Update Route'}>
      {alertsJsx}
      <RouteForm
        setAlert={setAlert}
        drivingRoute={drivingRoute}
        setShowModal={setShowModal}
        successHandler={successHandler}
      />
    </Modal>
  );
};

export default UpdateRouteModal;
