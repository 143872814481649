import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import keys from '../../img/keys.png'

// css classes
import classes from './dashboard.module.css'

const ApiBox = () => {
  return (
    <WhiteBox className={classes.minHeight}>
      <img height={98} src={keys} alt="keys" />
      <h3>API Keys</h3>
      <p>
        Generate and manage your API keys here.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/api-keys'} className={classes.button}>Manage Keys</Link>
        <a target={'_blank'} href={'https://docs.liftstation.cloud'} className={`${classes.button} ${classes.mt25}`}>View Documentation</a>
      </div>
    </WhiteBox>
  )
}

export default ApiBox