import React, { useEffect, useState, Fragment, useRef } from 'react';

import Container from '../../Components/UI/Container';
import CompleteCenter from '../../Components/UI/CompleteCenter';
import WhiteBackground from '../../Components/UI/WhiteBackground/WhiteBackground';
import FlexboxContainerHeader from '../../Components/UI/Heading/FlexboxContainerHeader';
import HeadingFlexItem from '../../Components/UI/Heading/HeadingFlexItem';
import GreenButton from '../../Components/UI/GreenButton/GreenButton';
import Spinner from '../../Components/UI/Spinner/Spinner';
import Border from '../../Components/UI/Heading/Border';
import Modal from '../../Components/UI/Modal/Modal';
import UpdateUserModal from './UpdateUserModal';

import axios from '../../axios';

import classes from './Users.module.css';
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid';
import User from './User';
import CreateUserModal from './CreateUserModal';
import DeleteUserModal from './DeleteUserModal';

const Users = () => {
  const [showCreateUserModal, setCreateUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState('Loading...');
  const [users, setUsers] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [deleteUserModalJsx, setDeleteUserModalJsx] = useState(null);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [updateUserModalJsx, setUpdateUserModalJsx] = useState(null);
  const [refCurrent , setRefCurrent] = useState(null)

  // refs
  const createUserModalRef = useRef(null)
  const updateUserModalRef = useRef(null)

  useEffect(() => {
    document.body.className = 'pinkBackground';
  }, []);

  useEffect(() => {
    axios
      .get('/users', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setOrganization(res.data.data.organization);
          setUsers([...res.data.data.users]);
          setLoading(false);
          console.log(res.data.data.users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get('/routes', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setRoutes(res.data.data.routes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openCreateModal = () => {
    setCreateUserModal(true);
  };

  const scrollToTopOfCreateUserModal = () => {
    const createUserModal = createUserModalRef.current
    createUserModal.scrollTop = 0
  }

  const scrollToTopOfUpdateUserModal = () => {
    const updateUserModal = updateUserModalRef.current
    updateUserModal.scrollTop = 0
  }

  const onUserUpdated = (user) => {
    setUsers(users.map((u) => (u.id === user.id ? user : u)));
    setShowUpdateUserModal(false);
  };

  const updateUserModalOnclickHandler = (id) => {
    const user = users.find((user) => user.id === id);
    setShowUpdateUserModal(true);
    setUpdateUserModalJsx(
      <UpdateUserModal
        innerRef={updateUserModalRef}
        user={user}
        successHandler={onUserUpdated}
        setShowModal={setShowUpdateUserModal}
        onUserUpdated={onUserUpdated}
        scrollToTop={scrollToTopOfUpdateUserModal}
      />
    );
    setRefCurrent(updateUserModalRef.current)
  };

  const deleteUserModalOnclickHandler = (id) => {
    setShowDeleteUserModal(true);
    setDeleteUserModalJsx(
      <DeleteUserModal
        id={id}
        users={users}
        setShowModal={setShowDeleteUserModal}
        successHandler={onUserDeleted}
      />
    );
  };

  const onUserDeleted = (id) => {
    setUsers(users.filter((user) => user.id !== id));
  };

  const onNewCreationOfUser = (user) => {
    setUsers((users) => [...users, user]);
  };

  const usersJsx = users.map((user) => (
    <User
      key={user.id}
      {...user}
      updateUserHandler={updateUserModalOnclickHandler}
      deleteUserHandler={deleteUserModalOnclickHandler}
    />
  ));

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <FlexboxContainerHeader>
                <HeadingFlexItem>
                  <h1>Users</h1>
                  <span className={'grayText'}>{organization}</span>
                </HeadingFlexItem>
                <HeadingFlexItem>
                  <GreenButton onClick={openCreateModal}>
                    Create a User
                  </GreenButton>
                </HeadingFlexItem>
              </FlexboxContainerHeader>
              <Border />
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <FlexboxContainerHeader>
              <HeadingFlexItem>
                <h1>Users</h1>
                <span className={'grayText'}>{organization}</span>
              </HeadingFlexItem>
              <HeadingFlexItem>
                <GreenButton onClick={openCreateModal}>
                  Create a User
                </GreenButton>
              </HeadingFlexItem>
            </FlexboxContainerHeader>
            <Border />
            <ThreeColumnGrid>{usersJsx}</ThreeColumnGrid>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
      {showCreateUserModal && (
        <CreateUserModal
          innerRef={createUserModalRef}
          drivingRoutes={routes}
          setShowModal={setCreateUserModal}
          successHandler={onNewCreationOfUser}
          confirmationButtonText={'Create a New User'}
          scrollToTop={scrollToTopOfCreateUserModal}
        />
      )}
      {showDeleteUserModal && deleteUserModalJsx}
      {showUpdateUserModal && updateUserModalJsx}
    </Fragment>
  );
};

export default Users;
