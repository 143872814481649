import React from 'react'

import classes from './ThreeColumnGrid.module.css'

const ThreeColumnGrid = ({ children }) => {
  return (
    <div className={classes.grid}>{children}</div>
  )
}

export default ThreeColumnGrid