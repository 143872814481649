import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import debug from '../../img/debug.png'

// css classes
import classes from './dashboard.module.css'

const DebugAppBox = () => {
  return (
    <WhiteBox className={classes.minHeight}>
      <img height={98} src={debug} alt="debug" />
      <h3>Debug App</h3>
      <p>
        Debug http 500 errors.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/debug'} className={classes.button}>Debug</Link>
      </div>
    </WhiteBox>
  )
}

export default DebugAppBox