import React, { useEffect, Fragment, useState } from 'react'

// Components
import Container from '../../Components/UI/Container'
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid'
import Spinner from '../../Components/UI/Spinner/Spinner'
import EmailVerifiedBox from './EmailVerifiedBox'
import CompleteCenter from '../../Components/UI/CompleteCenter'
import ApiBox from './ApiBox'
import VtScadaIntegrationBox from './VtScadaIntegrationBox'
import BillingBox from './BillingBox'
import ChangeWellMeterBox from './ChangeWellMeterBox'
import ChangeLiftStationPumpBox from './ChangeLiftStationPumpBox'
import EditOrganizationInfoBox from './EditOrganizationInfoBox'
import DebugAppBox from './DebugAppBox'


// css classes
import classes from './dashboard.module.css'

// api instance
import axios from "../../axios"

const Dashboard = () => {
  const [verified, setVerified] = useState(false)
  const [orgName, setOrgName] = useState('')
  const [operatorName, setOperatorName] = useState('')
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState('')
  const [debug, setDebug] = useState(false)

  const resendVerificationEmail = () => {
    axios.get('/users/resend-verification-email', {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res => {
      alert('Verification Email was resent')
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    document.body.className = 'login-page'
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/dashboard')
        setVerified(res.data.data.emailVerified)
        setOrgName(res.data.data.organization)
        setOperatorName(res.data.data.operator)
        setMessage(res.data.data.message)
        setDebug(res.data.data.debug)
        setLoading(false)
      } catch (error) {
        // put error handling for 500 errors here
      }
    }
    fetchData()
  }, [])

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <h1 className={`${classes.visibleHeader}`}>{'Loading...'}</h1>
          </CompleteCenter>
          <ThreeColumnGrid>
            <CompleteCenter>
              <Spinner />
            </CompleteCenter>
          </ThreeColumnGrid>
        </Container>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Container>

        <CompleteCenter>
          <h1 className={classes.visibleHeader}>{orgName}</h1>
        </CompleteCenter>
        <CompleteCenter>
          <h2 className={classes.visibleHeader}>{operatorName}</h2>
        </CompleteCenter>

        <ThreeColumnGrid>

          { (verified) ? (


          // if email is verified display email verified box at the bottom of page
          <Fragment>

            <ChangeWellMeterBox />

            <ChangeLiftStationPumpBox />

            <EditOrganizationInfoBox />

            <VtScadaIntegrationBox />

            <ApiBox />

            {/*<BillingBox />*/}

            <EmailVerifiedBox
              verified={verified}
              message={message}
              resendVerificationEmail={resendVerificationEmail}
            />

            { debug && <DebugAppBox /> }

          </Fragment>

          ) : (


          // if email is not verified display email not verified box at the top of page
          <Fragment>

            <EmailVerifiedBox
              verified={verified}
              message={message}
              resendVerificationEmail={resendVerificationEmail}
            />

            <EditOrganizationInfoBox />

            <ChangeWellMeterBox />

            <ChangeLiftStationPumpBox />

            <VtScadaIntegrationBox />

            <ApiBox />

            {/*<BillingBox />*/}

            { debug && <DebugAppBox /> }

          </Fragment>
          ) }


        </ThreeColumnGrid>
      </Container>
    </Fragment>
  )
}

export default Dashboard
