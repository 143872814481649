import React from 'react';

import { Link } from 'react-router-dom';

import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox';

import classes from './Permit.module.css';

const Permit = ({ title, stateImg, url }) => {
  return (
    <WhiteBox className={classes.permit}>
      <h2>{title}</h2>
      <img className={classes.img} src={stateImg} alt='Georgia' width='50%' />
      <Link className={classes.button} to={url}>
        Click Here
      </Link>
    </WhiteBox>
  );
};

export default Permit;
