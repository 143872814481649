import React, { useEffect, Fragment, useState } from 'react'

import { useParams } from 'react-router-dom'

import Container from '../../Components/UI/Container'

import './VerifyEmail.css'
import classes from './VerifyEmail.module.css'
import WhiteBox from './WhiteBox'
import CompleteCenter from "../../Components/UI/CompleteCenter";
import Spinner from "../../Components/UI/Spinner/Spinner";

import axios from '../../axios'

const VerifyEmail = () => {
  const { hash } = useParams()
  const[success, setSuccess] = useState(false)
  const[loading, setLoading] = useState(true)
  const [message, setMessage] = useState('')
  useEffect(() => {
    document.body.className = 'verifyEmailPage'
  }, [])

  useEffect(() => {
    console.log(hash)
    axios.get(`/verify-email/${hash}`).then(res => {
      setSuccess(true)
      setLoading(false)
      setMessage(res.data.message)
    }).catch(err => {
      setSuccess(false)
      setLoading(false)
      setMessage(err.response.data.message)
    })
  }, [])

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <Spinner />
          </CompleteCenter>
        </Container>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Container>
        <WhiteBox className={classes.inheritMinHeight} success={success}>
          {message}
        </WhiteBox>
      </Container>
    </Fragment>
  )
}

export default VerifyEmail