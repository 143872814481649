import React, { useState } from 'react';
import axios from '../../axios';

import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import WellForm from './WellForm';

const CreateWellModal = ({ setShowModal, successHandler }) => {
  const [wellName, setWellName] = useState('');
  const [sourceNumber, setSourceNumber] = useState(100);
  const [selectedRoute, setSelectedRoute] = useState('0');
  const [selectedSystem, setSelectedSystem] = useState('0');
  const [selectedGwwPermit, setSelectedGwwPermit] = useState('0');

  const createWellAlert = {
    id: 1,
    type: 'primary',
    message: 'To create a well, please enter a name for the well.',
  };

  const wellNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'Well name must be between 2 and 23 characters.',
  };

  const wellNameValid = {
    id: 2,
    type: 'success',
    message: 'Well name is valid.',
  };

  const [alert, setAlert] = useState([createWellAlert, wellNameInvalid]);

  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));

  const sourceNumberChangeHandler = (e) => {
    setSourceNumber(e.target.value);
  };

  const routeChangeHandler = (e) => {
    setSelectedRoute(e.target.value);
  };

  const systemChangeHandler = (e) => {
    setSelectedSystem(e.target.value);
  };
  const createWellHandler = (e) => {
    e.preventDefault();
    if (wellName.length >= 2 || wellName.length <= 25) {
      console.log('fired');
      axios
        .post(
          '/wells',
          {
            name: wellName,
            routeId: selectedRoute,
            systemId: selectedSystem,
            gwwPermitId: selectedGwwPermit === '0' ? null : selectedGwwPermit,
            sourceNumber: sourceNumber,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            successHandler(res.data.well);
            setShowModal(false);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          if (err.response.data.message) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => [
              ...alerts,
              { id: 2, type: 'danger', message: err.response.data.message },
            ]);
          }
        });
    }
  };

  const props = {
    setAlert,
    setShowModal,
    createWellHandler,
    wellNameValid,
    wellNameInvalid,
    wellName,
    setWellName,
    sourceNumber,
    sourceNumberChangeHandler,
    selectedRoute,
    routeChangeHandler,
    selectedSystem,
    systemChangeHandler,
    selectedGwwPermit,
    setSelectedGwwPermit
  };
  return (
    <Modal title={'Create Well'} setShowModal={setShowModal}>
      {alertsJsx}
      <WellForm {...props} />
    </Modal>
  );
};

export default CreateWellModal;
