import React, { useEffect, Fragment, useState } from 'react';

import classes from './Systems.module.css';
import HeadingFlexItem from '../../Components/UI/Heading/HeadingFlexItem';
import GreenButton from '../../Components/UI/GreenButton/GreenButton';
import Container from '../../Components/UI/Container';
import CompleteCenter from '../../Components/UI/CompleteCenter';
import WhiteBackground from '../../Components/UI/WhiteBackground/WhiteBackground';
import Spinner from '../../Components/UI/Spinner/Spinner';
import FlexboxContainerHeader from '../../Components/UI/Heading/FlexboxContainerHeader';

import axios from '../../axios';
import GrayText from '../../Components/UI/GrayText';
import Border from '../../Components/UI/Heading/Border';
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid';
import System from './System';
import CreateSystemModal from './CreateSystemModal';
import UpdateSystemModal from './UpdateSystemModal';
import DeleteSystemModal from './DeleteSystemModal';

const Systems = () => {
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState('Loading...');
  const [permits, setPermits] = useState([]);
  const [systems, setSystems] = useState([]);
  const [showCreateSystemModal, setShowCreateSystemModal] = useState(false);
  const [showUpdateSystemModal, setShowUpdateSystemModal] = useState(false);
  const [showDeleteSystemModal, setShowDeleteSystemModal] = useState(false);
  const [deleteSystemModalJsx, setDeleteSystemModalJsx] = useState(null);
  const [updateSystemModalJsx, setUpdateSystemModalJsx] = useState(null);

  const createSystemModalOnclickHandler = () => {
    setShowCreateSystemModal(true);
  };

  useEffect(() => {
    document.body.className = 'pinkBackground';
  }, []);

  useEffect(() => {
    axios
      .get('/systems', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setOrganization(res.data.organization);
          setSystems(res.data.systems);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get('/permits/ga/sdw', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setPermits(res.data.permits);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateSystemModalOnclickHandler = (id) => {
    const system = systems.find((system) => system.systemId === id);
    setShowUpdateSystemModal(true);
    setUpdateSystemModalJsx(
      <UpdateSystemModal
        setShowModal={setShowUpdateSystemModal}
        successHandler={onSystemUpdated}
        system={system}
        title='Update System'
        permits={permits}
      />
    );
  };

  const deleteSystemModalOnclickHandler = (id) => {
    const system = systems.find((system) => system.systemId === id);
    setShowDeleteSystemModal(true);
    setDeleteSystemModalJsx(
      <DeleteSystemModal
        id={id}
        system={system}
        setShowModal={setShowDeleteSystemModal}
        successHandler={onSystemDeleted}
      />
    );
  };

  const systemsJsx = systems.map((system) => (
    <System
      key={system.systemId}
      clasName={classes.fullHeight}
      updateSystemHandler={updateSystemModalOnclickHandler}
      deleteSystemHandler={deleteSystemModalOnclickHandler}
      {...system}
    />
  ));

  const onNewSystemCreated = (system) => {
    setSystems((systems) => systems.concat(system));
  };

  const onSystemUpdated = (system) => {
    setSystems((systems) => {
      return [
        ...systems.filter((_system) => _system.systemId !== system.systemId),
        system,
      ];
    });
  };

  const onSystemDeleted = (id) => {
    setSystems(systems.filter((system) => system.systemId !== id));
  };
  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <FlexboxContainerHeader>
                <HeadingFlexItem>
                  <h1>Systems</h1>
                  <GrayText>{organization}</GrayText>
                </HeadingFlexItem>
                <HeadingFlexItem>
                  <GreenButton>Create a System</GreenButton>
                </HeadingFlexItem>
              </FlexboxContainerHeader>
              <Border />
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <FlexboxContainerHeader>
              <HeadingFlexItem>
                <h1>Systems</h1>
                <GrayText>{organization}</GrayText>
              </HeadingFlexItem>
              <HeadingFlexItem>
                <GreenButton onClick={createSystemModalOnclickHandler}>
                  Create a System
                </GreenButton>
              </HeadingFlexItem>
            </FlexboxContainerHeader>
            <Border />
            <ThreeColumnGrid>{systemsJsx}</ThreeColumnGrid>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
      {showCreateSystemModal && (
        <CreateSystemModal
          setShowModal={setShowCreateSystemModal}
          title='Create a System'
          successHandler={onNewSystemCreated}
          permits={permits}
        />
      )}
      {showDeleteSystemModal && deleteSystemModalJsx}
      {showUpdateSystemModal && updateSystemModalJsx}
    </Fragment>
  );
};

export default Systems;
