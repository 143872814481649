import React, { useState, useEffect } from 'react';
import axios from '../../axios';

const WellForm = ({
  setAlert,
  setShowModal,
  well,
  createWellHandler,
  wellNameValid,
  wellNameInvalid,
  wellName,
  setWellName,
  sourceNumber,
  sourceNumberChangeHandler,
  selectedRoute,
  routeChangeHandler,
  selectedSystem,
  systemChangeHandler,
  updateWellHandler,
  selectedGwwPermit,
  setSelectedGwwPermit
}) => {
  const [routes, setRoutes] = useState([]);
  const [routeSelectOptions, setRouteSelectOptions] = useState(null);
  const [systems, setSystems] = useState([]);
  const [systemSelectOptions, setSystemSelectOptions] = useState(null);
  const [gwwPermits, setGwwPermits] = useState([])
  const [gwwPermitSelectOptions, setGwwPermitSelectOptions] = useState(null)

  useEffect(() => {
    axios
      .get('/routes', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setRoutes(res.data.data.routes);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    axios
      .get('/systems', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setSystems(res.data.systems);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get('/permits/ga/gww', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      }).then(res => {
        setGwwPermits(res.data.data.permits)
    }).catch(err => {
        console.log(err)
    })
  }, [])

  useEffect(() => {
    if (gwwPermits.length > 0) {
      setGwwPermitSelectOptions(gwwPermits.map(permit => <option key={permit.id} value={permit.id}>{permit.permitNumber}</option>))
    }
  }, [gwwPermits])

  useEffect(() => {
    if (systems.length > 0) {
      setSystemSelectOptions(
        systems.map((system) => (
          <option key={`sys-${system.systemId}`} value={system.systemId}>
            {system.systemName}
          </option>
        ))
      );
    }
  }, [systems]);

  useEffect(() => {
    if (routes.length > 0) {
      const routeSelectOptions = routes.map((route) => (
        <option key={`rou-${route.id}`} value={route.id}>
          {route.name}
        </option>
      ));
      setRouteSelectOptions(routeSelectOptions);
    }
  }, [routes]);

  const wellNameChangeHandler = (e) => {
    setWellName(e.target.value);
    if (e.target.value.length < 2 || e.target.value.length > 23) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(wellNameInvalid));
    }
    if (e.target.value.length >= 2 && e.target.value.length <= 23) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(wellNameValid));
    }
  };

  const gwwPermitChangeHandler = (e) => {
    setSelectedGwwPermit(e.target.value);
  }

  return (
    <form>
      <label htmlFor={'wellName'}>Well Name</label>
      <input
        id={'wellName'}
        className={'input'}
        type={'text'}
        onChange={wellNameChangeHandler}
        value={wellName}
      />
      <label htmlFor={'route'}>Route</label>
      <select
        value={selectedRoute}
        onChange={routeChangeHandler}
        className={'input'}
      >
        <option value={'0'}>Select a route</option>
        {routeSelectOptions}
      </select>
      <label htmlFor={'system'}>System</label>
      <select
        value={selectedSystem}
        onChange={systemChangeHandler}
        className={'input'}
      >
        <option value={'0'}>Select a system</option>
        {systemSelectOptions}
      </select>
      <label htmlFor={'GWW Permit'}>GWW Permit</label>
      <select value={selectedGwwPermit} className={'input'} onChange={gwwPermitChangeHandler}>
        <option value={'0'}>No Permit</option>
        {gwwPermitSelectOptions}
      </select>
      <label htmlFor='sourceNumber'>Source Number</label>
      <input
        id='sourceNumber'
        className='input'
        type='number'
        onChange={sourceNumberChangeHandler}
        value={sourceNumber}
        pattern={'[1]{1}[0-9]{2}'}
        min={101}
      />
      <div className={'form-footer'}>
        <button className={`btn cancelBtn`} onClick={() => setShowModal(false)}>
          Cancel
        </button>
        <button
          onClick={well ? updateWellHandler : createWellHandler}
          className={`btn confirmBtn`}
        >
          {well ? 'Update' : 'Create'}
        </button>
      </div>
    </form>
  );
};

export default WellForm;
