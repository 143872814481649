import React from 'react'

import classes from './BlueResourceBox.module.css'

const BlueResourceBox = props => {
  const classItems = props.className ? `${props.className} ${classes.resourceBox}` : `${classes.resourceBox}`
  return (
    <div className={classItems}>{props.children}</div>
  )
}

export default BlueResourceBox