import React, { useState } from 'react';
import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import axios from '../../axios';

const DeleteSystemModal = ({ id, setShowModal, successHandler, system }) => {
  const [systemName, setSystemName] = useState('');
  const [alert, setAlert] = useState([
    {
      id: 1,
      type: 'danger',
      message: `Type the name of the system and press delete. Warning: this is permanent!`,
    },
    {
      id: 2,
      type: 'warning',
      message: 'System names do not match',
    },
  ]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (systemName === system.systemName) {
      axios
        .delete(`/systems/${id}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setShowModal(false);
            successHandler(id);
          }
        })
        .catch((err) => {
          if (err.response.data.message) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => [
              ...alerts,
              { id: 2, type: 'danger', message: err.response.data.message },
            ]);
          }
        });
    }
  };
  const onChangeSystemName = (e) => {
    setSystemName(e.target.value);
    if (e.target.value !== system.systemName) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => {
        return alerts.concat({
          id: 2,
          type: 'warning',
          message: 'System names do not match',
        });
      });
    }
    if (e.target.value === system.systemName) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => {
        return alerts.concat({
          id: 2,
          type: 'success',
          message: 'System names match',
        });
      });
    }
  };
  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.id} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));

  return (
    <Modal setShowModal={setShowModal} title={'Delete System'}>
      {alertsJsx}
      <form>
        <label htmlFor={'systemName'}>System Name</label>
        <input
          id={'systemName'}
          className={'input'}
          type={'text'}
          placeholder={system.systemName}
          value={systemName}
          onChange={onChangeSystemName}
        />
        <div className={'footer-m'}>
          <button
            className={`btn cancelBtn`}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button className={`btn confirmBtn`} onClick={onSubmitHandler}>
            Delete
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteSystemModal;
