import React, { useState, Fragment, useEffect  } from 'react'
import CompleteCenter from '../../Components/UI/CompleteCenter'
import {formatPhoneNumber, removePhoneNumberFormatting} from '../../utils'
import validator from 'email-validator'

import axios from '../../axios'

const UserForm = ({ user, setShowModal, drivingRoutes, setAlerts, successHandler, scrollToTop }) => {
  const [phone, setPhone] = useState('')
  const [technician, setTechnician] = useState(false)
  const [operator, setOperator] = useState(false)
  const [email, setEmail] = useState('')
  const [validateEmail, setValidateEmail] = useState(false)
  const [technicianDrivingRoutes, setTechnicianDrivingRoutes] = useState([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [routeIds, setRouteIds] = useState([])
  const [routeObject, setRouteObject] = useState('')
  const [operatorClass, setOperatorClass] = useState('1')
  const [changeWellMeter, setChangeWellMeter] = useState(false)
  const [changeLiftStationDial, setChangeLiftStationDial] = useState(false)
  const [addGPSLocationWell, setAddGPSLocationWell] = useState(false)
  const [addGPSLocationLiftStation, setAddGPSLocationLiftStation] = useState(false)
  const [accessServiceLineInventory, setAccessServiceLineInventory] = useState(true)

  useEffect(() => {
    console.log(user)
  }, user)
 
  const phoneChangeHandler = e => {
    setPhone(formatPhoneNumber(e.target.value))
  }

  const emailChangeHandler = e => {
    setEmail(e.target.value)
    setValidateEmail(validator.validate(e.target.value))
  }
  const handleCheckbox = e => {
    setTechnicianDrivingRoutes(prevState => {
      for (let i = 0; i < prevState.length; i++) {
        if (parseInt(prevState[i].id) === parseInt(e.target.id)) {
          prevState[i].checked = !prevState[i].checked
        }
      }
      return [...prevState];
    })
  }

  const inputs = []
  const labels = []
  const technicianPermissions = []
  const routesJsx = [];
  technicianDrivingRoutes.forEach(route => {
    inputs.push(<input type={'checkbox'} id={route.id} key={route.id} checked={route.checked}
                        onChange={handleCheckbox} disabled={!technician}/>)
  })
  {
    technicianDrivingRoutes.forEach(route => {
      labels.push(<label key={`lab-${route.id}`} htmlFor={route.id}>{route.name}</label>)
    })
  }
    for (let i = 0; i < inputs.length; i++) {     
      routesJsx.push(inputs[i])
      routesJsx.push(labels[i])
    }


  useEffect(() => {
    if (user === null) {
      setTechnicianDrivingRoutes([...drivingRoutes])
    }
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setPhone(user.phone)
      setTechnician(user.technicianAccess)
      setOperator(user.operatorAccess)
      setOperatorClass(user.operatorClass)
      for (let i = 0; i < user.routes.length; i++) {
        user.routes[i].checked = user.routes[i].hasAccess
      }
      setTechnicianDrivingRoutes([...user.routes])
      setChangeLiftStationDial(user.technicianPermissions.changeLiftStationDial)
      setChangeWellMeter(user.technicianPermissions.changeWellMeter)
      setAddGPSLocationWell(user.technicianPermissions.setGPSLocationWell)
      setAddGPSLocationLiftStation(user.technicianPermissions.setGPSLocationLiftStation)
      setAccessServiceLineInventory(user.technicianPermissions.accessServiceLineInventory)

    }
  }, [])



      const operatorClassOnChange = e => {
        setOperatorClass(e.target.value)
      }


      const firstNameChangeHandler = e => {
        setFirstName(e.target.value)
      }

      const lastNameChangeHandler = e => {
        setLastName(e.target.value)
      }

      const updateUserHandler = e => {
        e.preventDefault()
        setAlerts([{
          id: 1,
          type: 'primary',
          message: 'Fill out the form to update a user.'
        }])
        const routeIds = technicianDrivingRoutes.filter(route => route.checked).map(route => route.id)

        if (!technician && !operator) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'You must select at least one access level (Operator or Technician).'
          }])
        }

        if (firstName.trim().length > 35 || firstName.trim().length < 1) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'First name must be between 1 and 35 characters.'
          }])
        }
        if (lastName.trim().length > 35 || lastName.trim().length < 1) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Last name must be between 1 and 35 characters.'
          }])
        }

        if (lastName.trim().length > 35 || lastName.trim().length < 1) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Last name must be between 1 and 35 characters.'
          }])
        }
        if (phone.length < 14) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Phone number must be at least 14 characters.'
          }])
        }

        const intPhone = removePhoneNumberFormatting(phone)
        const regex = /[+][1][0-9]{10}$/
        if (!intPhone.match(regex)) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Phone number is invalid format.'
          }])
        }

        if (operator) {
          if (email.trim().length < 1) {
            scrollToTop()
            return setAlerts([{
              id: 1,
              type: 'danger',
              message: 'Email is required for operator access.'
            }])
          }

          const validEmail = validator.validate(email)
          if (!validEmail) {
            scrollToTop()
            return setAlerts([{
              id: 1,
              type: 'danger',
              message: 'Email is invalid.'
            }])
          }
        }

        const userData = {
          firstName,
          lastName,
          email,
          phone: intPhone,
          technicianAccess: technician,
          technicianPermissions: {
            changeWellMeter,
            changeLiftStationPump: changeLiftStationDial,
            setGPSLocationWell: addGPSLocationWell,
            setGPSLocationLiftStation: addGPSLocationLiftStation,
            accessServiceLineInventory
          },
          operatorAccess: operator,
          operatorClass: operatorClass,
          routeIds: routeIds
        }

        axios.put(`/users/${user.id}`, userData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(res => {
          successHandler(res.data.user)
          setShowModal(false)
        }).catch(err => {
          setAlerts([{
            id: 1,
            type: 'danger',
            message: err.response.data.message
          }])
          scrollToTop()
        })
      }

      const createUserHandler = e => {
        setAlerts([{
          id: 1,
          type: 'primary',
          message: 'Fill out the form to add a new user.'
        }])
        const routeIds = technicianDrivingRoutes.filter(route => route.checked).map(route => route.id)
        e.preventDefault()

        if (!technician && !operator) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'You must select at least one access level (Operator or Technician).'
          }])
        }
        if (firstName.trim().length > 35 || firstName.trim().length < 1) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'First name must be between 1 and 35 characters.'
          }])
        }
        if (lastName.trim().length > 35 || lastName.trim().length < 1) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Last name must be between 1 and 35 characters.'
          }])
        }
        if (phone.length < 14) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Phone number must be at least 14 characters.'
          }])
        }
        const intPhone = removePhoneNumberFormatting(phone)
        const regex = /[+][1][0-9]{10}$/
        if (!intPhone.match(regex)) {
          scrollToTop()
          return setAlerts([{
            id: 1,
            type: 'danger',
            message: 'Phone number is invalid format.'
          }])
        }

        if (technician) {
          if (routeIds.length < 1) {
            scrollToTop()
            return setAlerts([{
              id: 1,
              type: 'danger',
              message: 'You must select at least one driving route.'
            }])
          }
        }

        if (operator) {
          if (email.trim().length < 1) {
            scrollToTop()
            return setAlerts([{
              id: 1,
              type: 'danger',
              message: 'Email is required for operator access.'
            }])
          }

          const validEmail = validator.validate(email)
          if (!validEmail) {
            scrollToTop()
            return setAlerts([{
              id: 1,
              type: 'danger',
              message: 'Email is invalid.'
            }])
          }
        }

        const userData = {
          firstName,
          lastName,
          email,
          phone: intPhone,
          technicianAccess: technician,
          technicianPermissions: {
            changeWellMeter,
            changeLiftStationPump: changeLiftStationDial,
            setGPSLocationWell: addGPSLocationWell,
            setGPSLocationLiftStation: addGPSLocationLiftStation,
            accessServiceLineInventory
          },
          operatorAccess: operator,
          operatorClass: operatorClass,
          routeIds: routeIds
        }

        axios.post('/users', userData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then(res => {
          successHandler(res.data.user)
          setShowModal(false)
        }).catch(err => {
          setAlerts([{
            id: 1,
            type: 'danger',
            message: err.response.data.message
          }])
          scrollToTop()
        })
      }

      return (
        <form>
          <label htmlFor={'firstName'}>First Name</label>
          <input type={'text'} id={'firstName'} value={firstName} onChange={firstNameChangeHandler} className={'input'}
                 placeholder={'John'}/>
          <label htmlFor={'lastName'}>Last Name</label>
          <input type={'text'} id={'lastName'} value={lastName} className={'input'} onChange={lastNameChangeHandler}
                 placeholder={'Doe'}/>
          <label htmlFor={'phone'}>Mobile</label>
          <input type={'tel'} id={'phone'} className={'input'} value={phone} onChange={phoneChangeHandler}
                 maxLength={'14'} placeholder={'Enter a mobile number'}/>
          <CompleteCenter>
            <input
              type={'checkbox'}
              checked={technician}
              onChange={() => setTechnician(!technician)}/>Technician
            <input
              type={'checkbox'}
              id={'operator'}
              checked={operator}
              onChange={() => setOperator(!operator)}
            />Operator
          </CompleteCenter>
          <input
            className={'input'}
            type={'email'}
            value={email}
            disabled={!operator}
            onChange={emailChangeHandler}
            placeholder={'Enter an Email Address'}
          />
          <select value={operatorClass} onChange={operatorClassOnChange} className={'input'} disabled={!operator}>
            <option value={'0'}>N/A</option>
            <option value={'1'}>Operator Class 1</option>
            <option value={'2'}>Operator Class 2</option>
            <option value={'3'}>Operator Class 3</option>
            <option value={'4'}>Operator Class 4</option>
          </select>
          <fieldset>
            <legend>Technician Permissions</legend>
            <div>
              <label htmlFor="changeWellMeter">
                <input
                  type="checkbox"
                  id="changeWellMeter"
                  checked={changeWellMeter}
                  onChange={() => setChangeWellMeter(!changeWellMeter)}
                />
                Change Well Meter
                <input
                  type="checkbox"
                  id="changeLiftStationDial"
                  checked={changeLiftStationDial}
                  onChange={() => setChangeLiftStationDial(!changeLiftStationDial)}
                />
                Change Lift Station Dial
                <input
                  type="checkbox"
                  id="setGPSLocationWell"
                  checked={addGPSLocationWell}
                  onChange={() => setAddGPSLocationWell(!addGPSLocationWell)}
                />
                Set GPS Location Well
                <input
                  type="checkbox"
                  id="setGPSLocationLiftStation"
                  checked={addGPSLocationWell}
                  onChange={() => setAddGPSLocationLiftStation(!addGPSLocationLiftStation)}
                />
                Set GPS Location Lift Station
                <input
                  type="checkbox"
                  id="accesServiceLineInventory"
                  checked={accessServiceLineInventory}
                  onChange={() => setAccessServiceLineInventory(!accessServiceLineInventory)}
                />
                Access Service Line Inventory
              </label>
            </div>
          </fieldset>
          <fieldset>
            <legend>Routes</legend>
            <div>
              <Fragment>
                {routesJsx}
              </Fragment>
            </div>
          </fieldset>
          <div className={'form-footer'}>
            <button className={'btn cancelBtn'} onClick={() => setShowModal(false)}>Cancel</button>
            <button className={'btn confirmBtn'}
                    onClick={user === null ? createUserHandler : updateUserHandler}>{user === null ? 'Create' : 'Update'}</button>
          </div>
        </form>
      )
    }
export default UserForm