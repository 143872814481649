import React, { useState, useEffect } from 'react';
import axios from '../../axios';

const LiftstationForm = ({
  setAlert,
  setShowModal,
  liftstation,
  createLiftstationHandler,
  liftstationNameValid,
  liftstationNameInvalid,
  liftstationName,
  setLiftstationName,
  selectedRoute,
  routeChangeHandler,
  selectedSystem,
  systemChangeHandler,
  updateLiftStationHandler,
}) => {
  const [routes, setRoutes] = useState([]);
  const [routeSelectOptions, setRouteSelectOptions] = useState(null);
  const [systems, setSystems] = useState([]);
  const [systemSelectOptions, setSystemSelectOptions] = useState(null);

  useEffect(() => {
    axios
      .get('/routes', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setRoutes(res.data.data.routes);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get('/systems', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setSystems(res.data.systems);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (systems.length > 0) {
      setSystemSelectOptions(
        systems.map((system) => (
          <option key={`sys-${system.systemId}`} value={system.systemId}>
            {system.systemName}
          </option>
        ))
      );
    }
  }, [systems]);

  useEffect(() => {
    if (routes.length > 0) {
      const routeSelectOptions = routes.map((route) => (
        <option key={`rou-${route.id}`} value={route.id}>
          {route.name}
        </option>
      ));
      setRouteSelectOptions(routeSelectOptions);
    }
  }, [routes]);

  const liftstationNameChangeHandler = (e) => {
    setLiftstationName(e.target.value);
    if (e.target.value.length < 2 || e.target.value.length > 23) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(liftstationNameInvalid));
    }
    if (e.target.value.length >= 2 && e.target.value.length <= 23) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(liftstationNameValid));
    }
  };

  return (
    <form>
      <label htmlFor={'wellName'}>Liftstation Name</label>
      <input
        id={'liftstationName'}
        className={'input'}
        type={'text'}
        onChange={liftstationNameChangeHandler}
        value={liftstationName}
      />
      <label htmlFor={'route'}>Route</label>
      <select
        value={selectedRoute}
        onChange={routeChangeHandler}
        className={'input'}
      >
        <option value={'0'}>Select a route</option>
        {routeSelectOptions}
      </select>
      <label htmlFor={'system'}>System</label>
      <select
        value={selectedSystem}
        onChange={systemChangeHandler}
        className={'input'}
      >
        <option value={'0'}>Select a system</option>
        {systemSelectOptions}
      </select>

      <div className={'form-footer'}>
        <button className={`btn cancelBtn`} onClick={() => setShowModal(false)}>
          Cancel
        </button>
        <button
          onClick={
            liftstation ? updateLiftStationHandler : createLiftstationHandler
          }
          className={`btn confirmBtn`}
        >
          {liftstation ? 'Update' : 'Create'}
        </button>
      </div>
    </form>
  );
};

export default LiftstationForm;
