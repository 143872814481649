import React from 'react';

import BlueResourceBox from '../../../Components/UI/BlueResourceBox/BlueResourceBox';
import Title from '../../../Components/UI/TitleAndContent/Title';
import Content from '../../../Components/UI/TitleAndContent/Content';
import Buttons from '../../../Components/UI/TitleAndContent/Buttons';

const SdwPermit = ({
  permitNumber,
  county,
  waterSystemType,
  waterSourceType,
  system,
  id,
  deleteHandler,
}) => {
  const systemName = system === null ? 'Unassigned' : system;

  return (
    <BlueResourceBox>
      <Title>County:</Title>
      <Content>{county}</Content>
      <Title>Water System Type:</Title>
      <Content>{waterSystemType}</Content>
      <Title>Water Source Type:</Title>
      <Content>{waterSourceType}</Content>
      <Title>System:</Title>
      <Content>{systemName}</Content>
      <Title>Permit Number:</Title>
      <Content>{permitNumber}</Content>
      <Buttons id={id} deleteHandler={deleteHandler} />
    </BlueResourceBox>
  );
};

export default SdwPermit;
