import React, { useState, useEffect, Fragment } from 'react';

import HeadingFlexItem from '../../Components/UI/Heading/HeadingFlexItem';
import GreenButton from '../../Components/UI/GreenButton/GreenButton';
import Container from '../../Components/UI/Container';
import CompleteCenter from '../../Components/UI/CompleteCenter';
import WhiteBackground from '../../Components/UI/WhiteBackground/WhiteBackground';
import Spinner from '../../Components/UI/Spinner/Spinner';
import FlexboxContainerHeader from '../../Components/UI/Heading/FlexboxContainerHeader';
import GrayText from '../../Components/UI/GrayText';
import classes from './Liftstation.module.css';

import axios from '../../axios';
import Border from '../../Components/UI/Heading/Border';
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid';
import Liftstation from './Liftstation';
import CreateLiftstationModal from './CreateLiftstationModal';
import UpdateLiftstationModal from './UpdateLiftstationModal';
import DeleteLiftstationModal from './DeleteLiftstationModal';
const Liftstations = () => {
  const [loading, setLoading] = useState(true);
  const [liftstations, setLiftstations] = useState([]);
  const [showCreateLiftstationModal, setShowCreateLiftstationModal] =
    useState(false);
  const [showUpdateLiftstationModal, setShowUpdateLiftstationModal] =
    useState(false);
  const [showDeleteLiftstationModal, setShowDeleteLiftstationModal] =
    useState(false);
  const [deleteLiftstationModalJsx, setDeleteLiftstationModalJsx] =
    useState(null);
  const [updateLiftstationModalJsx, setUpdateLiftstationModalJsx] =
    useState(null);

  useEffect(() => {
    document.body.className = 'pinkBackground';
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/lift-stations')

        if (res.data.success) {
          setLiftstations(res.data.liftStations)
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    };

    fetchData()
  }, [])


const onNewLiftstationCreated = (liftstation) => {
    setLiftstations([...liftstations, liftstation]);
  };
  const onLiftstationUpdated = (well) => {
    setLiftstations((liftStations) => {
      return [
        ...liftStations.filter((_liftstation) => _liftstation.id !== well.id),
        well,
      ];
    });
  };
  const onLiftstationDeleted = (id) => {
    setLiftstations(
      liftstations.filter((liftstation) => liftstation.id !== id)
    );
  };
  const updateLiftstationModalOnclickHandler = (id) => {
    const liftstation = liftstations.find(
      (liftStation) => liftStation.id === id
    );
    setShowUpdateLiftstationModal(true);
    setUpdateLiftstationModalJsx(
      <UpdateLiftstationModal
        setShowModal={setShowUpdateLiftstationModal}
        successHandler={onLiftstationUpdated}
        liftstation={liftstation}
        title='Update Liftstation'
      />
    );
  };
  const deleteLiftstationModalOnclickHandler = (id) => {
    const liftstation = liftstations.find(
      (liftstation) => liftstation.id === id
    );
    setShowDeleteLiftstationModal(true);
    setDeleteLiftstationModalJsx(
      <DeleteLiftstationModal
        id={id}
        liftstation={liftstation}
        setShowModal={setShowDeleteLiftstationModal}
        successHandler={onLiftstationDeleted}
      />
    );
  };
  const createLiftstationModalOnclickHandler = () => {
    setShowCreateLiftstationModal(true);
  };

  const liftStationsJsx = liftstations.map((liftstation) => (
    <Liftstation
      key={liftstation.id}
      clasName={classes.fullHeight}
      updateLiftstationHandler={updateLiftstationModalOnclickHandler}
      deleteLiftstationHandler={deleteLiftstationModalOnclickHandler}
      {...liftstation}
    />
  ));

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <FlexboxContainerHeader>
                <HeadingFlexItem>
                  <h1>Lift Stations</h1>
                </HeadingFlexItem>
                <HeadingFlexItem>
                  <GreenButton>Create a Lift Station</GreenButton>
                </HeadingFlexItem>
              </FlexboxContainerHeader>
              <Border />
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <FlexboxContainerHeader>
              <HeadingFlexItem>
                <h1>Lift Stations</h1>
              </HeadingFlexItem>
              <HeadingFlexItem>
                <GreenButton onClick={createLiftstationModalOnclickHandler}>
                  Create a Lift Station
                </GreenButton>
              </HeadingFlexItem>
            </FlexboxContainerHeader>
            <Border />
            <ThreeColumnGrid>{liftStationsJsx}</ThreeColumnGrid>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
      {showCreateLiftstationModal && (
        <CreateLiftstationModal
          setShowModal={setShowCreateLiftstationModal}
          successHandler={onNewLiftstationCreated}
        />
      )}
      {showDeleteLiftstationModal && deleteLiftstationModalJsx}
      {showUpdateLiftstationModal && updateLiftstationModalJsx}
    </Fragment>
  );
};

export default Liftstations;
