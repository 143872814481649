import React from 'react'

import classes from './GreenButton.module.css'

const GreenButton = ({ children, onClick }) => {
  return (
    <button onClick={onClick} className={classes.greenButton}>{children}</button>
  )
}

export default GreenButton