import React, { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import Container from '../../../Components/UI/Container'
import CompleteCenter from '../../../Components/UI/CompleteCenter'
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog'
import EmailVerification from './EmailVerification/EmailVerification'
import GaMorForm from './GaMorForm/GaMorForm'
import WhiteBackground from '../../../Components/UI/WhiteBackground/WhiteBackground'


import classes from './GaMor.module.css'
import './GaMor.css'
import axios from '../../../axios'

const GaMor = () => {
  const [alert, setAlert] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [permits, setPermits] = useState([])


  const alertMessages = alert.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)


  useEffect(() => {
    document.body.className = 'pinkBackground'
  }, [])

  useEffect(() => {
    axios.get('/users/verified', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      if (res.data.verified) {
        setShowForm(true)
        setEmailVerified(true)
        setAlert(alert => [...alert, {id: 1, type: 'primary', message: 'To generate a Monthly Operating Report, please select a permit number and a month.'}])
      }
    }).catch(err => {
      setAlert(alert => [...alert, {id: 2, type: 'warning', message: err.response.data.message }])
    })
  }, [])

  useEffect(() => {
    if (showForm) {
      axios.get('/permits/ga/sdw', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => {
        if (res.data.success) {
          for (let i = 0; i < res.data.permits.length; i++) {
            setPermits(prevPermitsState => [...prevPermitsState, {
              id: res.data.permits[i].id,
              permitNumber: res.data.permits[i].permitNumber,
              systemName: res.data.permits[i].system
            }])
          }
        }
      }).catch(err => {
        setAlert(alert => [...alert, {id: 3, type: 'warning', message: err.response.data.message }])
        setShowForm(false)
      })
    }
  }, [showForm])


  const permitOptions = permits.map(permit => <option key={permit.id} value={permit.id}>{`${permit.permitNumber} (${permit.systemName})`}</option>)

  const form = !emailVerified && <EmailVerification />

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
        <WhiteBackground>
          <h1 className='text-center'>Create Monthly Operating Report</h1>
          <CompleteCenter>
            <div>
              <Link className={classes.breadCrumbLink} to='/reports'>Reports</Link>
              <span className={'breadcrumb-separator'}>/</span>
              <span className={'breadcrumb-separator'}>Georgia Monthly Operating Report</span>
            </div>
          </CompleteCenter>
          <div className={classes.breadcrumbBorderBottom} />
          {alertMessages}
          <CompleteCenter>
            {showForm ? <GaMorForm setAlert={setAlert} options={permitOptions} /> : form}
          </CompleteCenter>
        </WhiteBackground>
        </CompleteCenter>
      </Container>
    </Fragment>
  )
}

export default GaMor