import React from 'react'

import classes from './WhiteBox.module.css'

const WhiteBox = ({children, className}) => {
  const cssClasses = [classes.whiteBox, className].join(' ')
  return (
    <div className={cssClasses}>{children}</div>
  )
}

export default WhiteBox