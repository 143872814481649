import React from 'react'

import classes from './Header.module.css'
import  well from '../../img/well.png'
import background from '../../img/background-header-home.png'
import CompleteCenter from '../../Components/UI/CompleteCenter'

const Header = () => {
  return (
      <header className={classes.headerLanding}>
        <CompleteCenter>
          <h1 className={classes.landingPageHeader}>One Stop Data Management For Water and Sewage</h1>
        </CompleteCenter>
        <div className={classes.flexContainer}>
          <p className={classes.appDescription}>
            LiftStation.cloud is a platform custom coded for water professionals in Georgia. It allows you to
            manage your permits and infrastructure through a web app. Data is collected digitally, through mobile
            apps or SCADA integration. Reports are generated with the click of a button.
          </p>
          <img src={well} alt="A water well" />
        </div>
      </header>
  )
}

export default Header