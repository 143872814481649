import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import excel from '../../img/excel.png'

// css classes
import classes from '../Dashboard/dashboard.module.css'

const MonthlyLiftStationReportBox = () => {
  return (
    <WhiteBox className={'.min-height-443px'}>
      <img height={98} src={excel} alt="excel" />
      <h3>Monthly Lift Station Report</h3>
      <p>
        Generate a Monthly Lift Station Report.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/reports/lift-station'} className={classes.button}>Generate Report</Link>
      </div>
    </WhiteBox>
  )
}

export default MonthlyLiftStationReportBox