import React, { useState, useEffect } from 'react';
import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import axios from '../../axios';

import SystemForm from './SystemForm';

const UpdateSystemModal = ({
  setShowModal,
  title,
  successHandler,
  permits,
  system,
}) => {
  const [systemName, setSystemName] = useState('');
  const [permitId, setPermitId] = useState('');
  const updateSystemAlert = {
    id: 1,
    type: 'primary',
    message: 'Type the name of the system and press update.',
  };

  const systemNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'System name must be between 2 and 25 characters.',
  };

  const systemNameValid = {
    id: 2,
    type: 'success',
    message: 'System name is valid.',
  };
  const [alert, setAlert] = useState([updateSystemAlert, systemNameValid]);

  useEffect(() => {
    if (system) {
      setSystemName(system.systemName);
    }
  }, []);

  const permitIdHandler = (e) => {
    setPermitId(e.target.value);
  };
  const systemNameHandler = (e) => {
    setSystemName(e.target.value);
    if (e.target.value.length < 2 || e.target.value.length > 25) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(systemNameInvalid));
    }
    if (e.target.value.length >= 2 && e.target.value.length <= 25) {
      setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlert((alerts) => alerts.concat(systemNameValid));
    }
  };
  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));
  const updateSystemHandler = (e) => {
    e.preventDefault();
    if (systemName.length >= 2 || systemName.length <= 25) {
      axios
        .put(
          `/systems/${system.systemId}`,
          {
            ...system,
            name: systemName,
            permitId: permitId,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            successHandler(res.data.system);
            setShowModal(false);
          }
        })
        .catch((err) => {
          if (err.response.data.message) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => [
              ...alerts,
              { id: 2, type: 'danger', message: err.response.data.message },
            ]);
          }
        });
    }
  };
  return (
    <Modal setShowModal={setShowModal} title={title} confirmButtonText={title}>
      {alertsJsx}
      <SystemForm
        setAlert={setAlert}
        setShowModal={setShowModal}
        systemNameHandler={systemNameHandler}
        permitIdHandler={permitIdHandler}
        updateSystemHandler={updateSystemHandler}
        systemName={systemName}
        system={system}
        permits={permits}
        permitId={permitId}
      />
    </Modal>
  );
};

export default UpdateSystemModal;
