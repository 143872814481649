import React, { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import Container from '../../../Components/UI/Container'
import CompleteCenter from '../../../Components/UI/CompleteCenter'
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog'
import EmailVerification from '../GaMor/EmailVerification/EmailVerification'
import GaGwwForm from './GaGwwForm'
import WhiteBackground from '../../../Components/UI/WhiteBackground/WhiteBackground'

import axios from '../../../axios'
import classes from "../GaMor/GaMor.module.css";

const GaGww = () => {
  const [alert, setAlert] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [systems, setSystems] = useState([])

  const alertMessages = alert.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)

  useEffect(() => {
    document.body.className = 'pinkBackground'
  }, [])

  useEffect(() => {
    axios.get('/users/verified', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      if (res.data.verified) {
        setShowForm(true)
        setEmailVerified(true)
        setAlert(alert => [...alert, {id: 1, type: 'primary', message: 'To generate a Groundwater Withdrawal Report, please select a system and a month.'}])
      }
    }).catch(err => {
      setAlert(alert => [...alert, {id: 2, type: 'warning', message: err.response.data.message }])
    })
  }, [])

  useEffect(() => {
    if (showForm) {
      axios.get('/gww/systems', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => {
        if (res.data.success) {
          for (let i = 0; i < res.data.systems.length; i++) {
            setSystems(prevSystemsState => [...prevSystemsState, {
              id: res.data.systems[i].id,
              name: res.data.systems[i].name
            }])
          }
        }
      }).catch(err => {
        setAlert(alert => [...alert, {id: 3, type: 'warning', message: err.response.data.message }])
        setShowForm(false)
      })
    }
  }, [showForm])

  const form = !emailVerified && <EmailVerification />

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <h1 className='text-center'>Create Groundwater Withdrawal Report</h1>
            <CompleteCenter>
              <div>
                <Link className={classes.breadCrumbLink} to='/reports'>Reports</Link>
                <span className={'breadcrumb-separator'}>/</span>
                <span className={'breadcrumb-separator'}>Georgia Groundwater Withdrawal Report</span>
              </div>
            </CompleteCenter>
            <div className={classes.breadcrumbBorderBottom} />
            {alertMessages}
            <CompleteCenter>
              {showForm ? <GaGwwForm setAlert={setAlert} systems={systems} /> : form}
            </CompleteCenter>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
    </Fragment>
  )
}

export default GaGww