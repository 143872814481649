import React from 'react'

import classes from './Users.module.css'

const OperatorClass = ({operatorClass}) => {
  const formattedOperatorClass = (parseInt(operatorClass) === 0) ? 'N/A' : operatorClass
  return (
    <div className={`${classes.marginTop} ${classes.centerText}`}>Operator Class: {formattedOperatorClass}</div>
  )
}

export default OperatorClass