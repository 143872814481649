import React, { useState } from 'react';

import axios from '../../../axios';

const SdwPermitForm = ({
  alerts,
  setShowModal,
  setCounty,
  setWaterSourceType,
  setWaterSystemType,
  successHandler,
  setAlerts,
}) => {
  const [permit, setPermit] = useState('');
  const [permitValid, setPermitValid] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState('Validate Permit');
  const [cancelButtonText, setCancelButtonText] = useState('Cancel');

  const permitInvalidLengthAlert = {
    id: 2,
    type: 'danger',
    message: 'Permit must be 9 characters long.',
  };

  const permitBeingValidatedAlert = {
    id: 2,
    type: 'warning',
    message: 'Validating permit...',
  };

  const permitValidAlert = {
    id: 2,
    type: 'success',
    message: 'Permit is valid.',
  };

  const permitInvalidAlert = {
    id: 2,
    type: 'danger',
    message: 'Permit is invalid.',
  };

  const createSdwPermitHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        '/permits/ga/sdw',
        {
          permitNumber: permit,
        },
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        successHandler(res.data.sdwPermit);
        setShowModal(false);
      })
      .catch((err) => {
        permitInvalidAlert.message = err.response.data.message;
        setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
        setAlerts((alerts) => {
          return alerts.concat(permitInvalidAlert);
        });
      });
  };

  const cancelButtonHandler = (e) => {
    e.preventDefault();
    if (formDisabled) {
      setFormDisabled(false);
      setPermit('');
      setWaterSystemType('Invalid Permit');
      setWaterSourceType('Invalid Permit');
      setCounty('Invalid Permit');
    } else {
      setShowModal(false);
    }
  };

  const permitOnChangeHandler = (e) => {
    setPermit(e.target.value);
    if (e.target.value.length < 9) {
      setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlerts((alerts) => {
        return alerts.concat(permitInvalidLengthAlert);
      });
    }
    if (e.target.value.length === 9) {
      setFormDisabled(true);
      setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlerts((alerts) => {
        return alerts.concat(permitBeingValidatedAlert);
      });
      axios
        .post(
          '/permits/ga/sdw/validate',
          {
            permit: e.target.value,
          }
        )
        .then((res) => {
          setPermitValid(true);
          setCounty(res.data.data.county);
          setWaterSystemType(res.data.data.waterSystemType);
          setWaterSourceType(res.data.data.waterSourceType);
          setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
          setAlerts((alerts) => {
            return alerts.concat(permitValidAlert);
          });
        })
        .catch((err) => {
          setCounty('Invalid Permit');
          setWaterSystemType('Invalid Permit');
          setWaterSourceType('Invalid Permit');
          permitInvalidAlert.message = err.response.data.message;
          setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
          setAlerts((alerts) => {
            return alerts.concat(permitInvalidAlert);
          });
        });
    }
  };

  return (
    <form onSubmit={createSdwPermitHandler}>
      <label className={'label'} htmlFor={'permit'}>
        Permit
      </label>
      <input
        disabled={formDisabled}
        className={'input'}
        type={'text'}
        maxLength={9}
        onChange={permitOnChangeHandler}
        value={permit}
        id={'permit'}
      />
      <div className={'form-footer'}>
        <button className={'btn cancelBtn'} onClick={cancelButtonHandler}>
          {formDisabled ? 'Clear' : 'Cancel'}
        </button>
        <button
          type={'submit'}
          disabled={!permitValid}
          className={'btn confirmBtn'}
        >
          {'Save Permit'}
        </button>
      </div>
    </form>
  );
};

export default SdwPermitForm;
