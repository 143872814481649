import React, { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import Container from '../../../Components/UI/Container'
import CompleteCenter from '../../../Components/UI/CompleteCenter'
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog'
import LiftStationForm from "./LiftStationForm/LiftStationForm";
import WhiteBackground from '../../../Components/UI/WhiteBackground/WhiteBackground'

import classes from '../GaMor/GaMor.module.css'
import '../GaMor/GaMor.css'
import axios from '../../../axios'

const LiftStationReport = () => {
  const [alert, setAlert] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [systems, setSystems] = useState([])

  const alertMessages = alert.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message}/>)

  useEffect(() => {
    document.body.className = 'pinkBackground'
  }, [])

  useEffect(() => {
    axios.get('/systems')
      .then(res => {
        const systemsWithStations = res.data.systems.filter(system => {
          if (system.numberOfStations > 0) {
            return {
              id: system.systemId,
              name: system.systemName
            }
          }
        })
        setSystems(systemsWithStations)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const permitOptions = systems.map(system => <option key={system.systemId} value={system.systemId}>{system.systemName}</option>)

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <h1 className={'text-center'}>Create Lift Station Report</h1>
            <CompleteCenter>
              <div>
                <Link className={classes.breadCrumbLink} to='/reports'>Reports</Link>
                <span className={'breadcrumb-separator'}>/</span>
                <span className={'breadcrumb-separator'}>Lift Station Report</span>
              </div>
            </CompleteCenter>
            <div className={classes.breadcrumbBorderBottom} />
            {alertMessages}
            <CompleteCenter>
              <LiftStationForm
                setAlert={setAlert}
                options={permitOptions}
              />
            </CompleteCenter>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
    </Fragment>
  )

}
export default LiftStationReport