import React from 'react'

import classes from './Users.module.css'

import isOperatorOrTechnician from '../../img/isOperatorOrTechnician.png'
import notOperatorOrTechnician from '../../img/notOperatorOrTechnician.png'

const TechnicianAndOperatorAccess = ({technicianAccess, operatorAccess}) => {
  const technicianIcon = technicianAccess ? isOperatorOrTechnician : notOperatorOrTechnician
  const operatorIcon = operatorAccess ? isOperatorOrTechnician : notOperatorOrTechnician
  return (
    <div className={`${classes.flexContainer} ${classes.marginTop}`}>
      <span>
        <img className={classes.routeIcon} src={technicianIcon} alt={'technician'} />
        Technician
      </span>
      <span>
        <img className={classes.routeIcon} src={operatorIcon} alt={'operator'} />
        Operator
      </span>
    </div>
  )
}

export default TechnicianAndOperatorAccess