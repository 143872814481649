import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import axios from '../../axios'

import Container from '../../Components/UI/Container'

import LoginBox from './LoginBox'

import classes from './Login.module.css'
import './Login.css'

const Login = () => {

  const navigate = useNavigate()
  const [displayPhone, setDisplayPhone] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    document.body.className = 'login-page'
  })

  const onSubmitPhone = phoneNumber => {
    setErrorMessage('')
    axios.post('/get-pin', {
      phone: phoneNumber,
      client: 'Web',
      access: 'Operator'
    }).then(res => {
      if (!res.data.success) {
        setErrorMessage(res.data.message)
      } else if (res.data.success) {
        setPhone(phoneNumber)
        setDisplayPhone(false)
      }
    }).catch(e => {
      console.log(e)
    })
  }

  useEffect(() => {
    console.log(redirect)
    if (redirect) {
      console.log('hello')
      window.location.href = '/dashboard'
    }
  }, [redirect])

  const onSubmitPin = pinNumber => {
    setErrorMessage('')
    axios.post('/login', {
      phone: phone,
      pin: pinNumber,
      client: 'Web'
    }).then(res => {
      if (res.data.success) {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userId', res.data.userId)
        setRedirect(true)
      } else {
        setErrorMessage(res.data.message)
      }
    }).catch(e => {
      console.log(e)
    })
  }

  return (
      <Fragment>
        <Container>
          <h1 className='text-center'>Login</h1>
          <main className={classes.loginSection}>
              <LoginBox
                displayPhone={displayPhone}
                onSubmitPhone={onSubmitPhone}
                onSubmitPin={onSubmitPin}
                errorMessage={errorMessage}
              />
          </main>
        </Container>
      </Fragment>
  )
}

export default Login