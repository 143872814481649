import React from 'react'

import classes from './WhiteBackground.module.css'

const WhiteBackground = ({children}) => {
  return (
    <div className={classes.whiteBackground}>
      {children}
    </div>
  )
}

export default WhiteBackground