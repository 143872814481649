import React from 'react'

import emailNotVerifiedIcon from '../../img/emailNotVerified.png'
import emailIsVerifiedIcon from '../../img/emailVerified.png'

import classes from './Users.module.css'

const Email = ({email, emailVerified}) => {
  const emailIcon = emailVerified ? emailIsVerifiedIcon : emailNotVerifiedIcon
  const hoverText = emailVerified ? 'Email is verified' : 'Email is not verified'
  return (
   <div title={hoverText} className={`${classes.centerText} ${classes.marginTop} ${classes.nowrap}`}>
      <img className={classes.emailIcon} src={emailIcon} alt={'email verified indicator'} />
      {email}
   </div>
  )
}

export default Email