import React from 'react'
import moduleClasses from './CompleteCenter.module.css'

const CompleteCenter = props => {
  const classes = props.className ? `${props.className} ${moduleClasses.center}` : moduleClasses.center
  return (
      <div className={classes}>{props.children}</div>
  )
}

export default CompleteCenter