import React from 'react'

import BlueResourceBox from '../../Components/UI/BlueResourceBox/BlueResourceBox'
import Buttons from '../../Components/UI/TitleAndContent/Buttons'
import Title from '../../Components/UI/TitleAndContent/Title'
import Content from '../../Components/UI/TitleAndContent/Content'

import classes from './Routes.module.css'


const Route = ({ id, name, numberOfWells, numberOfStations, clasName, deleteRouteHandler, updateRouteHandler }) => {
  return (
    <BlueResourceBox className={clasName}>
      <div className={classes.flexBoxContainer}>
        <div>
          <Title>Name:</Title>
          <Content>{name}</Content>
          <Title>Number of Wells:</Title>
          <Content>{numberOfWells}</Content>
          <Title>Number of Lift Stations:</Title>
          <Content>{numberOfStations}</Content>
        </div>
        <Buttons
          id={id}
          deleteHandler={deleteRouteHandler}
          successHandler={updateRouteHandler}
        />
      </div>
    </BlueResourceBox>
  )
}

export default Route