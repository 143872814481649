/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import CompleteCenter from '../../Components/UI/CompleteCenter';

import liIcon from '../../img/li-image.png';

import classes from './DataCollectionApp.module.css';

const DataCollectionApp = ({
  title,
  icon,
  googlePlay,
  appStore,
  playUrl,
  appUrl,
  ul,
}) => {
  return (
    <div className={classes.flexItem}>
      <CompleteCenter>
        <h3 className={classes.header}>{title}</h3>
      </CompleteCenter>
      <ul
        style={{ listStyleImage: `url(${liIcon})` }}
        className={classes.features}
      >
        {ul.map((li) => (
          // eslint-disable-next-line react/jsx-key
          <li>{li}</li>
        ))}
      </ul>
      <img className='center-image' src={icon} alt={`${title} App Icon`} />
      <div className={classes.flexContainer}>
        <a target='_blank' href={appUrl}>
          <img
            src={appStore}
            className={classes.responsive}
            alt='Apple App Store Icon'
          />
        </a>
        <a target='_blank' href={playUrl}>
          <img
            src={googlePlay}
            className={classes.responsive}
            alt='Google Play Store Icon'
          />
        </a>
      </div>
    </div>
  );
};

export default DataCollectionApp;
