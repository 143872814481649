import React, { useEffect, useState} from 'react'

import fileDownload from 'js-file-download'

import { getPreviousMonth, convertDateToMMDDYYYY } from '../../../../utils'

import BlueResourceBox from '../../../../Components/UI/BlueResourceBox/BlueResourceBox'

import axios from '../../../../axios'

import classes from './GaMorForm.module.css'

const GaMorForm = ({ options, setAlert }) => {
  const [permitId, setPermitId] = useState(-1)
  const [month, setMonth] = useState('')
  const [prepareReport, setPrepareReport] = useState(true)

  const prepareReportHandler = e => {
    e.preventDefault()
    if (permitId === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please select a permit number.'}])
    } else {
      if (month === '') {
        setAlert([])
        setAlert(alert => [...alert, {id: 5, type: 'warning', message: 'Please select a month.'}])
        // Generate Monthly Operating Report
      } else {
        setAlert([])
        // Generate Monthly Operating Report
        const monthDate = convertDateToMMDDYYYY(month)
        axios.get(`/mor/sdwPermit/${permitId}/date/${monthDate}/prepare`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
        }).then(res => {
          setAlert([])
          setAlert(alert => [...alert, {id: 1, type: 'success', message: 'Your report has been prepared. You may download it now.'}])
          setPrepareReport(false)
          }).catch(err => {
          setAlert(alert => [...alert, {id: 6, type: 'warning', message: err.response.data.message }])
        })
      }
    }
  }

  const downloadReportHandler = e => {
    e.preventDefault()
    if (permitId === -1) {
      setAlert([])
      setAlert(alert => [...alert, {id: 4, type: 'warning', message: 'Please select a permit number.'}])
    } else {
      if (month === '') {
        setAlert([])
        setAlert(alert => [...alert, {id: 5, type: 'warning', message: 'Please select a month.'}])
        // Generate Monthly Operating Report
      } else {
        setAlert([])
        // Generate Monthly Operating Report
        const monthDate = convertDateToMMDDYYYY(month)
        console.log(monthDate)
        axios.get(`/mor/sdwPermit/${permitId}/date/${monthDate}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          responseType: 'arraybuffer'
        }).then(res => {
          setAlert([])
          setAlert(alert => [...alert, {id: 1, type: 'primary', message: 'Downloading Monthly Operating Report...'}])
          const headerLine = res.headers['content-disposition']
          const startFileNameIndex = headerLine.indexOf('"') + 1
          const endFileNameIndex = headerLine.lastIndexOf('"')
          const fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          fileDownload(blob, fileName)
          setAlert(alerts => [...alerts, {id: 1, type: 'primary', message: 'Thank you for using LiftStation.cloud. :)'}])
          setPrepareReport(true)
        }).catch(err => {
          setAlert(alert => [...alert, {id: 6, type: 'warning', message: 'There was an error.' }])
        })
      }
    }
  }

  const selectChangeHandler = e => {
    setPermitId(e.target.value)
  }

  const monthChangeHandler = e => {
    setMonth(e.target.value)
  }

  const maxDate = getPreviousMonth(new Date())

  const cancelBtnHandler = () => {
    setPrepareReport(true)
    setAlert([])
  }

  return (
    <BlueResourceBox className={classes.setWidth}>
      <form onSubmit={prepareReport ? prepareReportHandler : downloadReportHandler}>
        <label className={classes.label} htmlFor={'permitNumber'}>Permit Number</label>
        <select disabled={!prepareReport} className={classes.select} id={'permitNumber'} onChange={selectChangeHandler} value={permitId}>
          <option key={-1} value={-1}>Please select a permit number</option>
          {options}
        </select>
        <label className={classes.label} htmlFor={'month'}>Month</label>
        <input disabled={!prepareReport} className={classes.month} id={'month'} onChange={monthChangeHandler} value={month} type={'month'} name={'month'} max={maxDate} />
        <div className={classes.buttonContainer}>
          <button className={'btn confirmBtn'} type={'submit'}>{prepareReport ? 'Prepare Report' : 'Download Report'}</button>
          {!prepareReport && <button onClick={cancelBtnHandler} className={'btn cancelBtn'}>Cancel</button>}
        </div>
      </form>
    </BlueResourceBox>
  )
}

export default GaMorForm