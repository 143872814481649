import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import organization from '../../img/organization.png'

// css classes
import classes from './dashboard.module.css'

const EditOrganizationInfoBox = () => {
  return (
    <WhiteBox className={classes.minHeight}>
      <img height={98} src={organization} alt="organization" />
      <h3>Edit Organization Info</h3>
      <p>
        Edit your organization information here.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/edit-organization-info'} className={classes.button}>Edit Info</Link>
      </div>
    </WhiteBox>
  )
}

export default EditOrganizationInfoBox