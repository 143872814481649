/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import { Link } from 'react-router-dom';

import home from '../../img/home-footer.png';
import login from '../../img/login-footer.png';
import register from '../../img/register-footer.png';

import classes from './Footer.module.css';

const Footer = () => {
  return (
    <div className={classes.footerWrapper}>
      <footer className={classes.footer}>
        <nav className={classes.nav}>
          <ul className={classes.navIcons}>
            <li>
              <Link to='/'>
                <img
                  className={classes.img}
                  src={home}
                  alt='Link to Homepage'
                />
              </Link>
            </li>
            <li>
              <Link to='/register'>
                <img
                  className={classes.img}
                  src={register}
                  alt='register link'
                />
              </Link>
            </li>
            <li>
              <Link to='/login'>
                <img className={classes.img} src={login} alt='login link' />
              </Link>
            </li>
          </ul>
        </nav>
        <ul className={classes.footerExternalLinks}>
          <li className={classes.flexBasis}>
            <a target='_blank' href='https://docs.liftstation.cloud'>
              Docs
            </a>
          </li>
          <li className={classes.flexBasis}>
            <a target='_blank' href='https://news.liftstation.cloud'>
              News
            </a>
          </li>
          <li className={classes.flexBasis}>
            <a target='_blank' href='https://privacy.liftstation.cloud'>
              Privacy
            </a>
          </li>
          <li className={classes.center}>
            <a target='_blank' href='https://terms.liftstation.cloud'>
              Terms
            </a>
          </li>
        </ul>
        <p className={classes.footerCopyright}>
          Copyright &copy; 2022, <span>LiftStation.cloud</span>. All Rights
          Reserved.
        </p>
        <p className={classes.footerDevelopment}>
          Development by{' '}
          <a target='_blank' href='https://consoli.tech'>
            Consoli Tech, LLC
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
