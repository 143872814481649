import React from 'react'

import classes from './GrayText.module.css'

const GrayText = ( { children } ) => {
  return (
    <span className={classes.text}>{children}</span>
  )
}

export default GrayText