import React, { Fragment, useEffect } from 'react'
import Container from '../../Components/UI/Container'
import { Link } from 'react-router-dom'

import CompleteCenter from '../../Components/UI/CompleteCenter'

import classes from './NotFound.module.css'


import notFound from '../../img/404.png'

const NotFound = () => {
  useEffect(() => {
    document.body.className = 'whiteBackground'
  })

  return (
      <Fragment>
        <Container className={classes.completeCenter}>
          <img className={classes.img} src={notFound} alt='404 error' />
          <h1 className='text-center'>Page Not Found</h1>
          <CompleteCenter>
            <Link className={classes.blueButton} to='/'>Go Home</Link>
          </CompleteCenter>
        </Container>
      </Fragment>
  )
}

export default NotFound