import React from 'react'

import classes from './AlertDialog.module.css'

const AlertDialog = props => {
  if (props.type === 'primary') {
    return (
        <div className={`${classes.primary} ${classes.alert}`}>{props.children}</div>
    )
  }
  if (props.type === 'warning') {
    return (
        <div className={`${classes.warning} ${classes.alert}`}>{props.children}</div>
    )
  }
  if (props.type === 'danger') {
    return (
      <div className={`${classes.danger} ${classes.alert}`}>{props.children}</div>
    )
  }
  if (props.type === 'success') {
    return (
      <div className={`${classes.success} ${classes.alert}`}>{props.children}</div>
    )
  }
}

export default AlertDialog