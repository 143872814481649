import React from 'react';

import CompleteCenter from '../CompleteCenter';

import classes from './TitleandContent.module.css';
import deleteIcon from '../../../img/delete-icon.png';
import editIcon from '../../../img/edit-icon.png';

const Buttons = ({ id, deleteHandler, successHandler }) => {
  return (
    <CompleteCenter>
      <input
        type={'image'}
        src={deleteIcon}
        alt={'Delete'}
        className={classes.deleteIcon}
        onClick={() => {
          deleteHandler(id);
        }}
      />
      {successHandler && (
        <input
          type={'image'}
          src={editIcon}
          alt={'Edit'}
          className={classes.editIcon}
          onClick={() => {
            successHandler(id);
          }}
        />
      )}
    </CompleteCenter>
  );
};

export default Buttons;
