import React, { Fragment, useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

import Container from '../../../Components/UI/Container'
import CompleteCenter from '../../../Components/UI/CompleteCenter'
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog'
import ResidualForm from './ResidualForm'
import WhiteBackground from '../../../Components/UI/WhiteBackground/WhiteBackground'

import classes from '../GaMor/GaMor.module.css'
import '../GaMor/GaMor.css'
import axios from '../../../axios'

const ResidualReport = () => {
  const [alert, setAlert] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [years, setYears] = useState([])

  useEffect(() => {
    let year = new Date().getFullYear()
    const yearsArray = []
    while (year !== 2021) {
      yearsArray.push(year)
      year = year - 1
    }
    setYears([
      ...yearsArray
    ])
  }, [])

  useEffect(() => {
    console.log(yearOptions)
  }, [years])

  const alertMessages = alert.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message}/>)
  const yearOptions = years.map(year => <option key={year} value={year}>{year}</option>)

  useEffect(() => {
    document.body.className = 'pinkBackground'
  }, [])

  return(
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <h1 className={'text-center'}>Create Annual Residual Report</h1>
            <CompleteCenter>
              <div>
                <Link className={classes.breadCrumbLink} to={'/reports'}>Reports</Link>
                <span className={'breadcrumb-separator'}>/</span>
                <span className={'breadcrumb-separator'}>Residual Report</span>
              </div>
            </CompleteCenter>
            <div className={classes.breadcrumbBorderBottom} />
            {alertMessages}
            <CompleteCenter>
              <ResidualForm
                options={yearOptions}
                setAlert={setAlert}
              />
            </CompleteCenter>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
    </Fragment>
  )
}

export default ResidualReport
