import React, { useEffect, Fragment } from 'react'

import Container from '../../Components/UI/Container'
import Permit from './Permit'

import './Permits.css'
import classes from './Permits.module.css'
import ga from '../../img/georgia.webp'

const Permits = () => {
  useEffect(() => {
    document.body.className = 'permitsPage'
  })

  const permits = [
    {
      id: 1,
      title: 'Safe Drinking Water Permits',
      stateImg: ga,
      url: '/permits/ga/sdw'
    },
    {
      id: 2,
      title: 'Ground Water Withdrawal Permits',
      stateImg: ga,
      url: '/permits/ga/gww'
    }
  ]

  const permitElements = permits.map(permit => <Permit key={permit.id} {...permit} />)

  return (
    <Fragment>
      <Container>
        <h1 className={classes.heading}>Permits</h1>
        <div className={classes.gridContainer}>
          {permitElements}
        </div>
      </Container>
    </Fragment>
  )
}

export default Permits