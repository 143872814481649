import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import waterMeter from '../../img/water-meter.png'

// css classes
import classes from './dashboard.module.css'

const ChangeWellMeterBox = () => {
  return (
    <WhiteBox className={classes.minHeight}>
      <img height={98} src={waterMeter} alt="water meter" />
      <h3>Change Well Meter</h3>
      <p>
        Change the meter for a well. Click View Documentation to learn more.
      </p>
      <div className={classes.buttonContainer}>
        <Link to={'/change-well-meter'} className={classes.button}>Change Meter</Link>
        <a target={'_blank'} href={'https://docs.liftstation.cloud'} className={`${classes.button} ${classes.mt25}`}>View Documentation</a>
      </div>
    </WhiteBox>
  )
}

export default ChangeWellMeterBox