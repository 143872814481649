import React, { useState } from 'react';

import Modal from '../../../Components/UI/Modal/Modal';
import AlertDialog from '../../../Components/UI/AlertDialog/AlertDialog';
import SdwPermitForm from './SdwPermitForm';
import Title from '../../../Components/UI/TitleAndContent/Title';
import Content from '../../../Components/UI/TitleAndContent/Content';

const CreateSdwPermitModal = ({ setShowModal, title, successHandler }) => {
  const [county, setCounty] = useState('Invalid Permit');
  const [waterSystemType, setWaterSystemType] = useState('Invalid Permit');
  const [waterSourceType, setWaterSourceType] = useState('Invalid Permit');
  const [alerts, setAlerts] = useState([
    {
      id: 1,
      type: 'primary',
      message:
        'Enter a permit. Once it has been entered the server will validate it and extract information. If it is valid you can save it.',
    },
  ]);

  const alertsJsx = alerts.map((alert) => (
    <AlertDialog key={alert.id} type={alert.type} children={alert.message} />
  ));

  return (
    <Modal title={title} setShowModal={setShowModal}>
      {alertsJsx}
      <Title children={'County'} />
      <Content children={county} />
      <Title children={'Water System Type'} />
      <Content children={waterSystemType} />
      <Title children={'Water Source Type'} />
      <Content children={waterSourceType} />
      <SdwPermitForm
        setShowModal={setShowModal}
        setAlerts={setAlerts}
        alerts={alerts}
        successHandler={successHandler}
        setCounty={setCounty}
        setWaterSystemType={setWaterSystemType}
        setWaterSourceType={setWaterSourceType}
      />
    </Modal>
  );
};

export default CreateSdwPermitModal;
