import React, { useState, useEffect, Fragment } from 'react';

import HeadingFlexItem from '../../Components/UI/Heading/HeadingFlexItem';
import GreenButton from '../../Components/UI/GreenButton/GreenButton';
import Container from '../../Components/UI/Container';
import CompleteCenter from '../../Components/UI/CompleteCenter';
import WhiteBackground from '../../Components/UI/WhiteBackground/WhiteBackground';
import Spinner from '../../Components/UI/Spinner/Spinner';
import FlexboxContainerHeader from '../../Components/UI/Heading/FlexboxContainerHeader';
import GrayText from '../../Components/UI/GrayText';
import CreateWellModal from './CreateWellModal';
import classes from './Wells.module.css';

import axios from '../../axios';
import Border from '../../Components/UI/Heading/Border';
import ThreeColumnGrid from '../../Components/UI/ThreeColumnGrid/ThreeColumnGrid';
import Well from './Well';

import UpdateWellModal from './UpdateWellModal';
import DeleteWellModal from './DeleteWellModal';

const Wells = () => {
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState('Loading...');
  const [wells, setWells] = useState([]);
  const [showCreateWellModal, setShowCreateWellModal] = useState(false);
  const [showUpdateWellModal, setShowUpdateWellModal] = useState(false);
  const [showDeleteWellModal, setShowDeleteWellModal] = useState(false);
  const [deleteWellModalJsx, setDeleteWellModalJsx] = useState(null);
  const [updateWellModalJsx, setUpdateWellModalJsx] = useState(null);

  useEffect(() => {
    document.body.className = 'pinkBackground';
  });

  useEffect(() => {
    axios
      .get('/wells', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.data.success) {
          setWells(res.data.wells);
          setOrganization(res.data.organizationName);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onNewWellCreated = (well) => {
    setWells([...wells, well]);
  };
  const onWellUpdated = (well) => {
    setWells((wells) => {
      return [...wells.filter((_well) => _well.id !== well.id), well];
    });
  };
  const onWellDeleted = (id) => {
    setWells(wells.filter((well) => well.id !== id));
  };
  const updateWellModalOnclickHandler = (id) => {
    const well = wells.find((well) => well.id === id);
    console.log('well: ', well);
    setShowUpdateWellModal(true);
    setUpdateWellModalJsx(
      <UpdateWellModal
        setShowModal={setShowUpdateWellModal}
        successHandler={onWellUpdated}
        well={well}
        title='Update Well'
      />
    );
  };
  const deleteWellModalOnclickHandler = (id) => {
    const well = wells.find((well) => well.id === id);
    setShowDeleteWellModal(true);
    setDeleteWellModalJsx(
      <DeleteWellModal
        id={id}
        well={well}
        setShowModal={setShowDeleteWellModal}
        successHandler={onWellDeleted}
      />
    );
  };
  const createWellModalOnclickHandler = () => {
    setShowCreateWellModal(true);
  };

  const wellsJsx = wells.map((well) => (
    <Well
      key={well.id}
      clasName={classes.fullHeight}
      updateWellHandler={updateWellModalOnclickHandler}
      deleteWellHandler={deleteWellModalOnclickHandler}
      {...well}
    />
  ));

  if (loading) {
    return (
      <Fragment>
        <Container>
          <CompleteCenter>
            <WhiteBackground>
              <FlexboxContainerHeader>
                <HeadingFlexItem>
                  <h1>Wells</h1>
                  <GrayText>{organization}</GrayText>
                </HeadingFlexItem>
                <HeadingFlexItem>
                  <GreenButton>Create a Well</GreenButton>
                </HeadingFlexItem>
              </FlexboxContainerHeader>
              <Border />
              <CompleteCenter>
                <Spinner />
              </CompleteCenter>
            </WhiteBackground>
          </CompleteCenter>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <CompleteCenter>
          <WhiteBackground>
            <FlexboxContainerHeader>
              <HeadingFlexItem>
                <h1>Wells</h1>
                <GrayText>{organization}</GrayText>
              </HeadingFlexItem>
              <HeadingFlexItem>
                <GreenButton onClick={createWellModalOnclickHandler}>
                  Create a Well
                </GreenButton>
              </HeadingFlexItem>
            </FlexboxContainerHeader>
            <Border />
            <ThreeColumnGrid>{wellsJsx}</ThreeColumnGrid>
          </WhiteBackground>
        </CompleteCenter>
      </Container>
      {showCreateWellModal && (
        <CreateWellModal
          setShowModal={setShowCreateWellModal}
          successHandler={onNewWellCreated}
        />
      )}
      {showDeleteWellModal && deleteWellModalJsx}
      {showUpdateWellModal && updateWellModalJsx}
    </Fragment>
  );
};

export default Wells;
