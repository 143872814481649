import React from 'react'

import accessRouteIcon from '../../img/accessRoute.png'
import noAccessRouteIcon from '../../img/noAccessRoute.png'

import classes from './Users.module.css'

const Route = ({name, hasAccess}) => {
  const routeIcon = hasAccess ? accessRouteIcon : noAccessRouteIcon
  return (
    <li className={classes.listStyle}>
      <img className={classes.routeIcon} src={routeIcon} alt={'route access indicator'} />
      {name}
    </li>
  )
}

export default Route