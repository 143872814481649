import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import classes from './RegisterBox.module.css'

const RegisterBox = () => {
  const [email, setEmail] = useState('')

  const emailChangeHandler = e => {
    setEmail(e.target.value)
  }

  return (
      <div className={classes.flexContainer}>
        <div className={classes.box}>
          <h1 className={classes.header}>Register Now</h1>
          <p className={classes.blackText}>
            We are currently in Beta. Leave your email and we will get in touch!
          </p>
          <form className={classes.form}>
            <input
                className={classes.emailInput}
                type='email'
                name='email'
                value={email}
                onChange={emailChangeHandler}
                placeholder='Enter Your Email Address'
            />
            <button className={classes.button} type='submit'>Submit</button>
            <div className={classes.blackText}>Already have an account?</div>
            <div className={classes.centerLink}>
              <Link className={classes.link} to='/login'>Login</Link>
            </div>
          </form>
        </div>
      </div>
  )
}

export default RegisterBox