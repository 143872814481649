import React, { useState } from 'react';
import axios from '../../axios';

import Modal from '../../Components/UI/Modal/Modal';
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog';
import WellForm from './WellForm';

const UpdateWellModal = ({ setShowModal, successHandler, well }) => {
  const [wellName, setWellName] = useState(well.wellName || '');
  const [sourceNumber, setSourceNumber] = useState(well.sourceNumber || 100);
  const [selectedRoute, setSelectedRoute] = useState(well.routeId || '0');
  const [selectedSystem, setSelectedSystem] = useState(well.systemId || '0');
  const [selectedGwwPermit, setSelectedGwwPermit] = useState(well.gwwPermitId || '0');

  const updateWellAlert = {
    id: 1,
    type: 'primary',
    message: 'Type the name of the well and press update.',
  };

  const wellNameInvalid = {
    id: 2,
    type: 'danger',
    message: 'Well name must be between 2 and 23 characters.',
  };

  const wellNameValid = {
    id: 2,
    type: 'success',
    message: 'Well name is valid.',
  };

  const [alert, setAlert] = useState([updateWellAlert, wellNameValid]);

  const alertsJsx = alert.map((alert) => (
    <AlertDialog key={alert.type} type={alert.type}>
      {alert.message}
    </AlertDialog>
  ));
  const sourceNumberChangeHandler = (e) => {
    setSourceNumber(e.target.value);
  };

  const routeChangeHandler = (e) => {
    setSelectedRoute(e.target.value);
  };

  const systemChangeHandler = (e) => {
    setSelectedSystem(e.target.value);
  };
  const updateWellHandler = (e) => {
    e.preventDefault();
    if (wellName.length >= 2 || wellName.length <= 25) {
      console.log('fired');
      axios
        .put(
          `/wells/${well.id}`,
          {
            ...well,
            name: wellName,
            routeId: selectedRoute,
            systemId: selectedSystem,
            gwwPermitId: selectedGwwPermit === '0' ? null : selectedGwwPermit,
            sourceNumber: sourceNumber,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            successHandler(res.data.well);
            setShowModal(false);
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
          if (err.response.data.message) {
            setAlert((alerts) => alerts.filter((alert) => alert.id !== 2));
            setAlert((alerts) => [
              ...alerts,
              { id: 2, type: 'danger', message: err.response.data.message },
            ]);
          }
        });
    }
  };

  const props = {
    setAlert,
    setShowModal,
    updateWellHandler,
    wellNameValid,
    wellNameInvalid,
    wellName,
    setWellName,
    sourceNumber,
    sourceNumberChangeHandler,
    selectedRoute,
    routeChangeHandler,
    selectedSystem,
    systemChangeHandler,
    well,
    selectedGwwPermit,
    setSelectedGwwPermit
  };
  return (
    <Modal title={'Update Well'} setShowModal={setShowModal}>
      {alertsJsx}
      <WellForm {...props} />
    </Modal>
  );
};

export default UpdateWellModal;
