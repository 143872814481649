import React from 'react';

const SystemForm = ({
  setAlert,
  setShowModal,
  systemNameHandler,
  permitIdHandler,
  createSystemHandler,
  updateSystemHandler,
  systemName,
  system,
  permits,
  permitId,
}) => {
  const permitsJsx = permits.map((permit) => (
    <option key={permit.id} value={permit.id}>
      {permit.permitNumber}
    </option>
  ));

  return (
    <form>
      <label htmlFor='systemName'>System Name</label>
      <input
        className={'input'}
        type='text'
        id='systemName'
        value={systemName}
        onChange={systemNameHandler}
      />
      <label htmlFor='permitId'>Permit</label>
      <select
        onChange={permitIdHandler}
        className={'input'}
        name={'permits'}
        value={permitId}
      >
        {permitsJsx}
      </select>
      <div className={'form-footer'}>
        <button className={`btn cancelBtn`} onClick={() => setShowModal(false)}>
          Cancel
        </button>
        <button
          onClick={system ? updateSystemHandler : createSystemHandler}
          className={`btn confirmBtn`}
        >
          {system ? 'Update' : 'Create'}
        </button>
      </div>
    </form>
  );
};

export default SystemForm;
