import React from 'react'

import classes from './TitleandContent.module.css'
import arrowIcon from '../../../img/arrow-icon.png'

const Title = ({children}) => {
  return (
    <div className={classes.title}>
      <img src={arrowIcon} alt={'Arrow'} />
      <span className={classes.spacing}>{children}</span>
     </div>
  )
}

export default Title