import React, { useState } from 'react'

import BlueResourceBox from "../../../Components/UI/BlueResourceBox/BlueResourceBox";

import { getCurrentMonth } from "../../../utils";

import classes from './WellForm.module.css'

const WellForm = ({ options, setAlerts, handler }) => {

  const [selectedOption, setSelectedOption] = useState(-1)
  const [month, setMonth] = useState('')

  const max = getCurrentMonth(new Date());

  const selectChangeHandler = (e) => {
    setSelectedOption(e.target.value)
  }

  const monthChangeHandler = (e) => {
    setMonth(e.target.value)
  }

  const formSubmitHandler = (e) => {
    e.preventDefault()
    if (selectedOption === -1) {
      setAlerts([{
        id: 1,
        type: 'warning',
        message: 'Please select a permit well.'
      }])
    } else {
      if (month === '') {
        setAlerts([{
          id: 2,
          type: 'warning',
          message: 'Please select a month.'
        }])
      } else {
        const [selectedYear, selectedMonth] = month.split('-')
        const selectedDay = '01'
        const selectedDate = [selectedMonth, selectedDay, selectedYear].join('-')
        handler(selectedOption, selectedDate)
      }
    }
  }

  return (
    <BlueResourceBox className={classes.form}>
      <form onSubmit={formSubmitHandler}>
        <label htmlFor={'wells'}>Select a Well</label>
        <select value={selectedOption} className={'input'} onChange={selectChangeHandler}>
        <option value={-1}>Select a Well</option>
        {options}
      </select>
      <label htmlFor={'month'}>Select a Month</label>
      <input id={'month'} type={'month'} className={'input'} max={max} onChange={monthChangeHandler}/>
      <button type={'submit'} className={'btn confirmBtn'}>Submit</button>
      </form>
    </BlueResourceBox>
  )
}

export default WellForm