import React from 'react';
import BlueResourceBox from '../../Components/UI/BlueResourceBox/BlueResourceBox';
import Title from '../../Components/UI/TitleAndContent/Title';
import Content from '../../Components/UI/TitleAndContent/Content';
import Buttons from '../../Components/UI/TitleAndContent/Buttons';

const System = ({
  systemId,
  systemName,
  sdwPermitNumber,
  sdwPermitId,
  numberOfWells,
  numberOfStations,
  updateSystemHandler,
  deleteSystemHandler,
}) => {
  return (
    <BlueResourceBox>
      <Title>Name:</Title>
      <Content>{systemName}</Content>
      <Title>Safe Drinking Water Permit Number:</Title>
      <Content>{sdwPermitNumber}</Content>
      <Title>Number of Wells:</Title>
      <Content>{numberOfWells}</Content>
      <Title>Number of Lift Stations:</Title>
      <Content>{numberOfStations}</Content>
      <Buttons
        id={systemId}
        deleteHandler={deleteSystemHandler}
        successHandler={updateSystemHandler}
      />
    </BlueResourceBox>
  );
};

export default System;
