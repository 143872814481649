import React from 'react'

import classes from './WebApp.module.css'

const WebApp = () => {
  return (
      <section className={classes.section}>
        <h2>A Web App for Administration and Report Generation</h2>
        <div className={classes.wrapper}>
          <p>
            The web app servers two purposes: administration of users and resources and report generation. Whenever you
            need
            to add a new permit, well or lift station you simply fill out a web form, and it is added to the database.
            From within the web app you are able to view data that was entered for wells or lift stations and alter it
            if
            a mistake was made.
          </p>
          <p>
            Report generation is seamless. Generating a report is as simple as picking the report you want and the time
            period.
          </p>
        </div>
      </section>
  )
}

export default WebApp