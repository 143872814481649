import React from 'react'

import classes from './Heading.module.css'

const FlexboxContainerHeader = ({children}) => {
  return (
    <div className={classes.flexContainer}>{children}</div>
  )
}

export default FlexboxContainerHeader