import React from 'react'

import LoginForm from './LoginForm'

import classes from './Login.module.css'
import LoginBoxImage from '../../img/login-box-image.png'
import phone2 from '../../img/phone2.png'

const LoginBox = ({ displayPhone, onSubmitPhone, onSubmitPin, errorMessage }) => {
  if (!displayPhone) {
    return (
      <div className={classes.loginBox}>
        <img className='center-image' src={phone2} alt='Phone' />
        <h2 className='text-center'>Enter Your PIN</h2>
        <LoginForm
          displayPhone={displayPhone}
          onSubmitPhone={onSubmitPhone}
          onSubmitPin={onSubmitPin}
          errorMessage={errorMessage}
        />
      </div>
    )
  }

  if (displayPhone) {
    return (
      <div className={classes.loginBox}>
        <img className="center-image" src={LoginBoxImage} alt="Phone"/>
        <h2 className='text-center'>Enter Your Phone Number</h2>
        <LoginForm
          displayPhone={displayPhone}
          onSubmitPhone={onSubmitPhone}
          onSubmitPin={onSubmitPin}
          errorMessage={errorMessage}
        />
      </div>
    )
  }
}

export default LoginBox