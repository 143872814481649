import React, { Fragment, useEffect } from 'react'

import Container from '../../Components/UI/Container'
import Header from './Header'
import TwoApps from './TwoApps'
import WebApp from './WebApp'

import technicianIcon from '../../img/technician-icon.png'
import operatorIcon from '../../img/operator-icon.png'
import googlePlayIcon from '../../img/google-play.png'
import appStoreIcon from '../../img/apple-app-store.png'

import './Home.css'

const Home = () => {

  useEffect(() => {
    document.body.className = 'landing-page'
  })

  const apps = [
    {
      title: 'Technician',
      ul: [
        'Collect Well Data',
        'Collect Lift Station Data',
        'Features an Offline Mode for Internet Outages or Spotty Connections'
      ],
      icon: technicianIcon,
      googlePlay: googlePlayIcon,
      appStore: appStoreIcon,
      playUrl: 'https://play.google.com/store/apps/details?id=com.consolidatedutilities.liftstation.cloud&hl=en&gl=US',
      appUrl: 'https://apps.apple.com/us/app/technician-liftstation-cloud/id1553483969'
    },
    {
      title: 'Operator',
      ul: [
        'Collect Plant Data',
        'Web App Access',
        'Features an Offline Mode for Internet Outages or Spotty Connections'
      ],
      icon: operatorIcon,
      googlePlay: googlePlayIcon,
      appStore: appStoreIcon,
      playUrl: '#',
      appUrl: '#'
    }
  ]


  return (
      <Fragment>
        <Container>
          <Header />
          <TwoApps apps={apps} />
          <WebApp />
        </Container>
      </Fragment>
  )
}

export default Home