import React, { useState, useEffect } from 'react';

import axios from '../../../axios';

const GwwPermitForm = ({
  setShowModal,
  setAlerts,
  setCounty,
  successHandler,
}) => {
  const [permit, setPermit] = useState('');
  const [permitValid, setPermitValid] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [aquifers, setAquifers] = useState([]);
  const [selectedAquifer, setSelectedAquifer] = useState('0');
  const [aquiferOptions, setAquiferOptions] = useState(null);
  const [systems, setSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState('0');
  const [systemOptions, setSystemOptions] = useState(null);

  const permitInvalidLengthAlert = {
    id: 2,
    type: 'danger',
    message: 'Permit must be 8 characters long.',
  };

  const permitBeingValidatedAlert = {
    id: 2,
    type: 'warning',
    message: 'Validating permit...',
  };

  const permitValidAlert = {
    id: 2,
    type: 'success',
    message: 'Permit is valid.',
  };

  const permitInvalidAlert = {
    id: 2,
    type: 'danger',
    message: 'Permit is invalid.',
  };

  useEffect(() => {
    axios
      .get('/aquifers')
      .then((res) => {
        if (res.data.success) {
          setAquifers(res.data.aquifers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get('/systems')
      .then((res) => {
        setSystems(res.data.systems);
      })
      .catch((err) => {
        alert('/systems ')
        console.log(err);
      });
  }, []);

  const formatPermit = e => {
    return (e.target.value.length === 3 && e.nativeEvent.data !== null) ? `${e.target.value}-` : e.target.value
  }

  const permitOnChangeHandler = (e) => {
    const permit = formatPermit(e)

    setPermit(permit);

    if (e.target.value.length < 8) {
      setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlerts((alerts) => {
        return alerts.concat(permitInvalidLengthAlert);
      });
    }

    if (e.target.value.length === 8) {
      setFormDisabled(true);
      setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
      setAlerts((alerts) => {
        return alerts.concat(permitBeingValidatedAlert);
      });

      axios
        .post(
          '/permits/ga/gww/validate',
          {
            permit: e.target.value,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((res) => {
          setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
          setAlerts((alerts) => {
            return alerts.concat(permitValidAlert);
          });
          setPermitValid(true);
          setCounty(res.data.data.county);
        })
        .catch((err) => {
          setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
          permitInvalidLengthAlert.message = err.response.data.message;
          setAlerts((alerts) => {
            return alerts.concat(permitInvalidAlert);
          });
          setPermitValid(false);
        });
    }
  };

  useEffect(() => {
    if (aquifers.length > 0) {
      setAquiferOptions(
        aquifers.map((aquifer) => (
          <option key={`aquifer-${aquifer.id}`} value={aquifer.id}>
            {aquifer.name}
          </option>
        ))
      );
    }
  }, [aquifers]);

  useEffect(() => {
    if (systems.length > 0) {
      setSystemOptions(
        systems.map((system) => (
          <option key={`sys${system.systemId}`} value={system.systemId}>
            {system.systemName}
          </option>
        ))
      );
    }
  }, [systems]);

  const cancelButtonHandler = (e) => {
    e.preventDefault();
    if (formDisabled) {
      setFormDisabled(false);
      setPermit('');
      setCounty('Invalid Permit');
    } else {
      setShowModal(false);
    }
  };

  const systemOnChangeHandler = (e) => {
    setSelectedSystem(e.target.value);
  };

  const aquiferOnChangeHandler = (e) => {
    setSelectedAquifer(e.target.value);
  };

  const createGwwPermitHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        '/permits/ga/gww',
        {
          permitNumber: permit,
          aquiferId: selectedAquifer,
          systemId: selectedSystem,
        },
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        successHandler(res.data.permit);
        setShowModal(false);
      })
      .catch((err) => {
        permitInvalidAlert.message = err.response.data.message;
        setAlerts((alerts) => alerts.filter((alert) => alert.id !== 2));
        setAlerts((alerts) => {
          return alerts.concat(permitInvalidAlert);
        });
      });
  };

  return (
    <form onSubmit={createGwwPermitHandler}>
      <label htmlFor={'aquifers'}>Aquifer</label>
      <select
        className='input'
        value={selectedAquifer}
        onChange={aquiferOnChangeHandler}
      >
        <option value={'0'}>Select an Aquifer</option>
        {aquiferOptions}
      </select>
      <label htmlFor={'systems'}>Systems</label>
      <select
        className={'input'}
        value={selectedSystem}
        onChange={systemOnChangeHandler}
      >
        <option value={'0'}>Select a System</option>
        {systemOptions}
      </select>
      <label className={'label'} htmlFor={'permit'}>
        Permit
      </label>
      <input
        disabled={formDisabled}
        maxLength={8}
        className={'input'}
        id={'permit'}
        type={'text'}
        value={permit}
        onChange={permitOnChangeHandler}
      />
      <div className={'form-footer'}>
        <button className={'btn cancelBtn'} onClick={cancelButtonHandler}>
          {formDisabled ? 'Clear' : 'Cancel'}
        </button>
        <button
          type={'submit'}
          disabled={!permitValid}
          className={'btn confirmBtn'}
        >
          {'Save Permit'}
        </button>
      </div>
    </form>
  );
};

export default GwwPermitForm;
