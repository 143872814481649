import React, { useState } from 'react'

import axios from '../../axios'

import Modal from '../../Components/UI/Modal/Modal'
import AlertDialog from '../../Components/UI/AlertDialog/AlertDialog'

const DeleteUserModal = ({ setShowModal, id, users, successHandler}) => {
  const user = users.find(user => user.id === id)
  const [userName, setUserName] = useState('')
  const [userFullName, setUserFullName] = useState(() => {
    const user = users.find(user => user.id === id)
    return `${user.firstName} ${user.lastName}`
  })
  const [alert, setAlert] = useState([{
    id: 1,
    type: 'danger',
    message: `Type the name of the user and press delete. Warning: this is permanent!`
  }, {
    id: 2,
    type: 'warning',
    message: 'User names do not match'
  }])

  const onChangeUserName = e => {
    e.preventDefault()
    setUserName(e.target.value)
    if (e.target.value !== userFullName) {
      setAlert(alerts => alerts.filter(alert => alert.id !== 2))
      setAlert(alerts => {
        return alerts.concat({
          id: 2,
          type: 'warning',
          message: 'User names do not match'
        })
      })
    }
    if (e.target.value === userFullName) {
      setAlert(alerts => alerts.filter(alert => alert.id !== 2))
      setAlert(alerts => {
        return alerts.concat({
          id: 2,
          type: 'success',
          message: 'User names match'
        })
      })
    }
  }

  const onSubmitHandler = e => {
    e.preventDefault()
    if (userName === userFullName) {
      axios.delete(`/users/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          setShowModal(false)
          successHandler(id)
        }
      }).catch(err => {
        setAlert(alerts => alerts.filter(alert => alert.id !== 2))
        setAlert(alerts => {
          return alerts.concat({
            id: 2,
            type: 'danger',
            message: err.response.data.message
          })
        })
      })
    }
  }
  const alertsJsx = alert.map(alert => <AlertDialog key={alert.id} type={alert.type} children={alert.message} />)
  return (
    <Modal
      setShowModal={setShowModal}
      title={'Delete User'}
    >
      {alertsJsx}
      <form onSubmit={onSubmitHandler}>
        <label htmlFor={'userName'}>User Full Name</label>
        <input
          type={'text'}
          id={'userName'}
          className={'input'}
          onChange={onChangeUserName}
          value={userName}
          placeholder={userFullName}
          />
        <div className={'footer-m'}>
          <button className={'btn cancelBtn'} onClick={() => setShowModal(false)}>Cancel</button>
          <button type={'submit'} className={'btn confirmBtn'}>Delete</button>
        </div>
      </form>
    </Modal>
  )
}

export default DeleteUserModal