import React from 'react'

import { Link } from 'react-router-dom'

// Components
import WhiteBox from '../../Components/UI/WhiteBox/WhiteBox'

// icons and images
import wellIcon from '../../img/well-icon.png'

const WellReadingsBox = () => {
  return (
    <WhiteBox>
      <img height={98} src={wellIcon} alt="well-icon" />
      <h3>Well Readings</h3>
      <p>
        View well readings here.
      </p>
      <div className={'buttonContainer'}>
        <Link to={'/readings/well'} className={'boxButton'}>View Readings</Link>
      </div>
    </WhiteBox>
  )
}

export default WellReadingsBox